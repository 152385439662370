import { authorizedApi } from '@chew/common/services/network/authorized-api';

import { ResponseDataWrapper, unwrap } from '@/util/fixtures/api';

export interface CreateOrderRequestBody {
  subscriptionPlanId: string;
}

export interface CreateOrderResponse {
  amount: number;
  html_snippet: string;
  message: string;
  order_id: string;
  success: boolean;
}

export const createOrder = (subscriptionPlanId: string) => {
  return authorizedApi
    .post<ResponseDataWrapper<CreateOrderResponse>, CreateOrderRequestBody>('/payment/klarna/order/create', {
      subscriptionPlanId
    })
    .then(unwrap);
};

export interface ReadOrderResponse {
  html_snippet: string;
  order_id: string;
  status: string;
  success: boolean;
  message: string;
}

export const readOrder = (orderId: string) => {
  return authorizedApi
    .get<ResponseDataWrapper<ReadOrderResponse>>(`/payment/klarna/order/read?orderId=${orderId}`)
    .then(unwrap);
};

export interface ConfirmOrderRequestBody {
  orderId: string;
}

export interface ConfirmOrderResponse {
  success: boolean;
  message: string;
}

export const confirmOrder = (orderId: string) => {
  return authorizedApi
    .post<ResponseDataWrapper<ConfirmOrderResponse>, ConfirmOrderRequestBody>('/payment/klarna/order/confirm', {
      orderId
    })
    .then(unwrap);
};

export interface ApplyPromocodeRequestBody {
  orderId: string;
  promocode: string;
}

export interface ApplyPromocodeResponse {
  amount: number;
  html_snippet: string;
  message: string;
  order_id: string;
  promocode: string;
  success: boolean;
}

export const applyPromocode = (data: ApplyPromocodeRequestBody) => {
  return authorizedApi
    .post<ResponseDataWrapper<ApplyPromocodeResponse>, ApplyPromocodeRequestBody>(
      '/payment/klarna/order/promocode',
      data
    )
    .then(unwrap);
};
