import React from 'react';
import { useAsync } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { translations } from '@/locales';
import { renderSnippet } from '@/util/klarna';
import { analytics } from '@/services/analytics';
import { metaPixelAnalytics } from '@/services/analytics/meta-pixel';
import { confirmOrder, readOrder } from '@/services/api/payments';

import { Card } from '@/components/Card';
import { PagePadding } from '@/components/Page';
import { Session } from '@/containers/SessionContainer';

const KLARNA_CONFIRMATION_CONTAINER_ID = 'klarna-confirmation-container';

export const ConfirmSubscriptionPage: React.FC = () => {
  const { orderId } = useParams();
  const { loadMe } = Session.useContainer();
  const navigate = useNavigate();

  if (!orderId) {
    navigate('/subscriptions');

    return null;
  }

  const { error } = useAsync(async () => {
    const response = await readOrder(orderId);

    renderSnippet(response.html_snippet, KLARNA_CONFIRMATION_CONTAINER_ID);
    await confirmOrder(orderId).then(() => {
      loadMe();
    });
  }, [orderId]);

  React.useEffect(() => {
    if (error) return;

    analytics.trackEvent('Payment - Finalised', {});
    metaPixelAnalytics.trackEvent('Purchase', {});
  }, []);

  return (
    <PagePadding className="py-6 md:py-12">
      <Card className="mb-4">
        <Card.Row className="w-full">
          <div id={KLARNA_CONFIRMATION_CONTAINER_ID} />

          {!!error && (
            <div className="font-bold">
              <FormattedMessage id={translations.pages.subscriptions.confirm.error} />
            </div>
          )}
        </Card.Row>
      </Card>
    </PagePadding>
  );
};
