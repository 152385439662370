import useSWR from 'swr';

import { getLesson } from '@/services/api/courses';

export const useLoadLesson = (lessonId: number) => {
  return useSWR({ key: `getLesson`, lessonId }, ({ key, ...o }) => getLesson(o), {
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
