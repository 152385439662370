import React from 'react';
import classnames from 'classnames';
import { GiftIcon } from '@heroicons/react/solid';

export interface Props {
  className?: string;
  onClick: () => void;
}

export const ApplyDiscountCodeButton = ({ className, children, ...rest }: React.PropsWithChildren<Props>) => {
  return (
    <button className={classnames('flex items-center hover:text-orange-light group', className)} {...rest}>
      <GiftIcon className="w-4 h-5 fill-orange-light hidden md:flex " />

      <span className="ml-1 text-purple-medium underline font-bold group-hover:text-orange-light md:inline">
        {children}
      </span>
    </button>
  );
};
