import React from 'react';

import { ValidationMessage, ValidationError } from '@chew/common/components/ValidationMessage';

export interface Props {
  invalid?: boolean;
  error?: ValidationError | null;
}

export const withValidation =
  <P, SP>(Component: React.ComponentType<P>, staticProps?: Partial<SP>) =>
  ({ invalid, error, ...rest }: P & Props & Partial<SP>) => {
    const normalizedError = error || ({} as any);

    return (
      <div className="w-full">
        {/* @ts-ignore */}
        <Component invalid={!!error} {...staticProps} {...rest} />

        <ValidationMessage visible={!!invalid} id={normalizedError.id} values={normalizedError.values} />
      </div>
    );
  };
