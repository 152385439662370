import { getDigitalTask } from '@/services/api/digital-tasks';
import { Level } from '@chew/common/domains';
import useSWR from 'swr';

export const useLockedByDigitalTask = (levelId?: number, levels?: Level[]) => {
  const levelIds = levels?.map((level) => level.id);
  const currentLevelIndex = levelId ? levelIds?.indexOf(levelId) : null;

  const { data, error } = useSWR(
    levelId && levels && currentLevelIndex
      ? { key: `getLevelPreviousTask`, levelId: levels[currentLevelIndex - 1].id }
      : false,
    ({ key, ...o }) => getDigitalTask(o),
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  if (levelId === 1) {
    return false;
  }

  if (error || !data) return false;

  return !data.completed;
};
