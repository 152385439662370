import useSWR from 'swr';

import { getDigitalTask } from '@/services/api/digital-tasks';

export const useLoadDigitalTask = (levelId?: number) => {
  return useSWR(levelId ? { key: `getDigitalTask`, levelId } : false, ({ key, ...o }) => getDigitalTask(o), {
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
