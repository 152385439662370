import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ArrowNarrowRightIcon, RefreshIcon, CheckIcon, XIcon } from '@heroicons/react/outline';
import classnames from 'classnames';
import * as yup from 'yup';

import { translations } from '@/locales';
import { DigitalTask, DigitalTaskResult, DigitalTaskType } from '@/domains/digital-task';

import { Button } from '@chew/common/components/Button';
import { Form, FormField } from '@chew/common/components/Form';

const text = translations.modals.digitalTaskModal;

const schema = yup
  .object({
    answers: yup.array().of(yup.string().required()).required()
  })
  .required();

interface Props {
  digitalTask: DigitalTask<DigitalTaskType>;
  result?: DigitalTaskResult;
  onSubmit(answers: string[]): Promise<any>;
  onComplete(): void;
}

const buildItemClassName = ({
  selected,
  correct,
  submitted
}: {
  selected: boolean;
  correct?: boolean;
  submitted: boolean;
}) => {
  if (!submitted && selected) return 'border-orange-light';
  if (!submitted) return 'border-transparent';

  if (selected && !correct) return 'border-orange-light';
  if (selected && correct) return 'border-[#58C977]';

  return 'border-transparent';
};

const initialValues = { answers: [] };

export const DigitalTaskGameContent: React.FC<Props> = ({ digitalTask, result, onSubmit, onComplete }) => {
  return (
    <Form
      {...{ schema, initialValues }}
      className="block space-y-6"
      onSubmit={(values) => onSubmit(values.answers).then(() => null)}
    >
      {({ values, submitSucceeded, form }) => {
        const submitted = submitSucceeded && !!result;
        const succeeded = submitSucceeded && !!result?.correct;

        return (
          <React.Fragment>
            <div className="text-3xl font-extrabold text-center">
              {submitted ? (
                <FormattedMessage id={succeeded ? text.success.title : text.failure.title} />
              ) : (
                digitalTask.title
              )}
            </div>

            <div className="text-lg text-purple-medium">
              {submitted ? (
                <FormattedMessage id={succeeded ? text.success.message : text.failure.message} />
              ) : (
                digitalTask.description
              )}
            </div>

            <ul className="grid grid-cols-3 gap-4">
              {digitalTask.data.items.map((item) => {
                const selected = values.answers.includes(item.name);
                const correct = selected
                  ? !!result?.correctAnswers.includes(item.name)
                  : !result?.correctAnswers.includes(item.name);

                return (
                  <li key={item.name}>
                    <label
                      className={classnames(
                        'relative block justify-center rounded-lg aspect-square overflow-hidden border-2 bg-gray-100',
                        {
                          ['cursor-pointer']: !submitSucceeded,
                          [buildItemClassName({
                            selected,
                            submitted,
                            correct
                          })]: true
                        }
                      )}
                    >
                      <FormField
                        as="input"
                        type="checkbox"
                        name="answers"
                        value={item.name}
                        hidden
                        disabled={submitSucceeded}
                      />

                      <img
                        src={item.image.url}
                        role="presentation"
                        className="w-[70%] mx-auto aspect-square object-contain"
                      />

                      <p className="absolute left-0 right-0 bottom-2 text-sm text-center font-bold">{item.name}</p>

                      {submitted
                        ? values.answers.includes(item.name) && (
                            <div
                              className={classnames(
                                'absolute center inline-flex items-center justify-center w-10 h-10 text-white rounded-full',
                                correct ? 'bg-[#58C977]' : 'bg-orange-light'
                              )}
                            >
                              {correct ? <CheckIcon className="w-6 h-6" /> : <XIcon className="w-6 h-6" />}
                            </div>
                          )
                        : values.answers.includes(item.name) && (
                            <div className="absolute top-2 right-2">
                              <div className="flex items-center justify-center w-5 h-5 rounded-full text-white bg-orange-light">
                                <CheckIcon className="w-4 h-4" />
                              </div>
                            </div>
                          )}
                    </label>
                  </li>
                );
              })}
            </ul>

            <div>
              {!!submitted && succeeded ? (
                <Button
                  type="button"
                  appearance="gradient-orange"
                  className="w-full h-16"
                  onClick={(event: MouseEvent) => {
                    event.preventDefault();
                    onComplete();
                  }}
                >
                  <FormattedMessage id={text.success.button} />
                </Button>
              ) : !!submitted && !succeeded ? (
                <Button
                  type="button"
                  appearance="gradient-orange"
                  className="w-full h-16"
                  onClick={(event: MouseEvent) => {
                    event.preventDefault();
                    form.reset();
                  }}
                >
                  <FormattedMessage id={text.failure.button} />
                  <RefreshIcon className="w-4 h-4 ml-2" />
                </Button>
              ) : (
                <Button type="submit" appearance="gradient-orange" className="w-full h-16">
                  <FormattedMessage id={text.uncompleted.button} />
                  <ArrowNarrowRightIcon className="w-4 h-4 ml-2" />
                </Button>
              )}
            </div>
          </React.Fragment>
        );
      }}
    </Form>
  );
};
