import React from 'react';

import { getContinueWatching } from '@/util/me';
import { Session } from '@/containers/SessionContainer';

export const useContinueWatchingLessons = () => {
  const { me } = Session.useContainer();

  return React.useMemo(() => getContinueWatching(me).filter((lesson) => lesson.metadata), [me]);
};
