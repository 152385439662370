import { PointType, Profile } from '@chew/common/domains';
import { api } from '@chew/common/services/network/api';
import { authorizedApi } from '@chew/common/services/network/authorized-api';

import { ResponseDataWrapper, unwrap } from '@/util/fixtures/api';
import { createFormDataFromObject } from '@/util/formData';

export interface LoginResponse {
  token: string;
  success: boolean;
  message: string;
  user: Profile;
}

export interface LoginOptions {
  email: string;
  password: string;
}

export interface LoginRequestBody {
  email: string;
  password: string;
}

export interface SocialAuthOptions {
  oauth_client: 'google' | 'facebook';
  oauth_client_id: string;
  oauth_client_picture?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

export enum SocialAuthIntent {
  Registration = 'registration',
  Login = 'login'
}

export const login = (options: LoginOptions) =>
  api.post<ResponseDataWrapper<LoginResponse>, LoginRequestBody>(`/user/login`, options).then(unwrap);

export const loginWithSocial = (options: SocialAuthOptions) =>
  api.post<ResponseDataWrapper<LoginResponse>, SocialAuthOptions>(`/user/login`, options);

export const register = (email: string) =>
  api.post<{ data: { success: boolean; message: string } }>(`/user/registration`, { email });

export const registerWithSocial = (options: SocialAuthOptions) =>
  api.post<ResponseDataWrapper<LoginResponse>, SocialAuthOptions>(`/user/socials/registration`, options);

export const forgotPassword = (email: string) => api.post<{ email: string }>(`/user/forgot`, { email });

export const confirmUser = (email: string, password: string) =>
  api.post<{ email: string; password: string }>(`/user/confirm`, { email, password });

export const updatePoints = (moduleId: string, type: PointType) =>
  authorizedApi.post<{ moduleId: string; type: string }>(`/user/points`, { moduleId, type });

export interface GetProfileResponse extends Profile {
  message: string;
  success: boolean;
}

export const getProfile = () =>
  authorizedApi.get<ResponseDataWrapper<GetProfileResponse>>(`/user/profile`).then(unwrap);

export const updateProfile = (data: {
  fullName: string;
  firstName: string;
  lastName: string;
  age: string;
  avatar: string;
}) => {
  return authorizedApi.put<{ fullName: string; firstName: string; lastName: string; age: string }>(
    '/user/profile',
    createFormDataFromObject(data)
  );
};

export const sendRecipeToEmail = (lessonId: string) =>
  authorizedApi.post<{ lessonId: string }>(`/user/send/recipe`, { lessonId });

export const logout = () => authorizedApi.post<void>(`/user/logout`);
