import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { LegacyBadge } from '@chew/common/domains';

import { DashboardStatItem } from '@/components/DashboardStatItem';
import { Badges } from './Badges';

import wavingHand from '@/assets/waving-hand.png';

interface Props {
  pointsEarned: number;
  progressLevel: number;
  progressPercent: number;
  lessonsWatched: number;
  firstName: string;
  avatar: string;
  badges: LegacyBadge[];
}

export const Stats: React.FC<Props> = ({
  pointsEarned,
  progressLevel,
  progressPercent,
  lessonsWatched,
  avatar,
  firstName,
  badges
}) => {
  return (
    <div className="relative xs:pl-14">
      <div className="bg-white w-full rounded-2xl mt-20 md:mt-0 p-6 flex flex-col xs:flex-row xs:items-center gap-6 shadow">
        <div className="rounded-3xl w-32 aspect-square box-border bg-red-100 border-4 border-solid border-white drop-shadow-beige -rotate-6 -mt-20 xs:mt-0 xs:-ml-20 overflow-hidden">
          <img src={avatar} className="h-full w-full object-cover" />
        </div>

        <div className="w-full">
          <div className="text-3xl font-extrabold mb-6 mt-4 lg:mt-0 flex items-center">
            <FormattedMessage id={translations.pages.dashboard.stats.title} values={{ name: firstName }} />{' '}
            <img src={wavingHand} className="h-8 ml-2" />
          </div>

          <div className="flex sm:flex-row flex-wrap gap-2 sm:gap-4 mt-2">
            <DashboardStatItem type="xp" value={pointsEarned} />

            <DashboardStatItem type="level" value={progressLevel} />

            <DashboardStatItem type="completedLessons" value={lessonsWatched} />
          </div>

          <div className="flex flex-col mt-6 md:max-w-md">
            <div className="flex justify-between font-extrabold text-sm">
              <div>
                <FormattedMessage id={translations.pages.dashboard.stats.levelProgress} />
              </div>

              <div>
                <FormattedMessage
                  id={translations.pages.dashboard.stats.lessonProgress}
                  values={{ number: Math.ceil(progressPercent) }}
                />
              </div>
            </div>

            <div className="bg-[#F3ECE8] w-full h-3 rounded-full overflow-hidden my-1 group">
              <div
                className="bg-gradient-to-tr from-orange-light to-orange-dark h-full rounded-full animate-pulse md:animate-none group-hover:animate-pulse"
                style={{ width: `${progressPercent}%` }}
              />
            </div>
          </div>
        </div>

        <Badges {...{ badges }} className="hidden lg:flex w-[40%] flex-col items-start self-start ml-auto" />
      </div>
    </div>
  );
};
