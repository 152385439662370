import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';

import { translations } from '@/locales';

import { Button } from '@chew/common/components/Button';

const text = translations.application.error;

export const ErrorBoundary = ({ children }: React.PropsWithChildren) => (
  <Sentry.ErrorBoundary
    showDialog={false}
    fallback={({ eventId, resetError }) => (
      <div className="w-full min-h-screen flex items-center justify-center relative">
        <div className="max-w-2xl lg:max-w-3xl">
          <div className="text-headline-1 mb-6 font-medium">
            <FormattedMessage id={text.title} values={{ br: <br /> }} />
          </div>

          <div className="mb-4">
            <FormattedMessage id={text.message} />
          </div>

          <div className="mb-4">{eventId}</div>

          <Button onClick={resetError} appearance="gradient-orange" className="px-4">
            <FormattedMessage id={text.button} />
          </Button>
        </div>
      </div>
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
);
