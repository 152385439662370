import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { translations } from '@/locales';

import clockImage from './clock.png';

interface Props {
  duration: string;
  className?: string;
}

export const LessonDuration: React.FC<Props> = ({ duration, className }) => (
  <p className={classnames('flex items-center font-inter tracking-wide', className)}>
    <img src={clockImage} role="presentation" className="w-4 h-4 -mt-[2px] mr-2" />

    <FormattedMessage id={translations.domains.lesson.duration} values={{ duration }} />
  </p>
);
