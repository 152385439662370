import React from 'react';

export const Collections: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ul className="grid gap-8">
    {React.Children.toArray(children).map((child, index) => (
      <li key={index}>{child}</li>
    ))}
  </ul>
);

interface Props {
  title: React.ReactNode;
}

export const CollectionHeading: React.FC<React.PropsWithChildren<Props>> = ({ title, children }) => (
  <header className="flex items-center justify-between pb-2">
    <h2 className="text-[1.5rem] font-bold">{title}</h2>
    {!!children && <div>{children}</div>}
  </header>
);

export const CollectionScrollableContent: React.FC<React.PropsWithChildren> = (props) => (
  <div {...props} className="max-w-[100vw] overflow-x-auto scrollbar-hide" />
);

/**
 * One of the parents is CollectionScrollableContent which is horizontally scrollable.
 * Because of this the right padding is lost, so we will have to fake it.
 * The width of the fake element should match the padding set by the PagePadding.
 */
export const CollectionScrolledList: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ul className="flex flex-nowrap -mx-2">
    {React.Children.toArray(children).map((child, index) => (
      <li key={index} role="article" className="flex-shrink-0 w-full min-w-[300px] max-w-xs md:max-w-[25%]">
        <div className="mx-2">{child}</div>
      </li>
    ))}

    <li className="flex-shrink-0 -ml-2 w-4 sm:w-12 md:w-20" />
  </ul>
);
