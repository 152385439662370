import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Level } from '@chew/common/domains';
import { Session } from '@/containers/SessionContainer';

const parseLevelParam = (param?: string) => {
  if (!param) return;

  const level = parseInt(param);

  if (!level) return;

  return level;
};

export const useLevelIdQueryParam = (levels: Level[]) => {
  const { me } = Session.useContainer();
  const [params, setParams] = useSearchParams();

  const levelId = React.useMemo(() => {
    if (!levels.length) return;

    const paramLevel = parseLevelParam(params.get('level') ?? undefined);

    if (paramLevel) return paramLevel;

    if (!me?.levelsCompleted) return levels[0].id;

    return levels.find(({ lvl }) => lvl === me.levelsCompleted + 1)?.id ?? levels[0].id;
  }, [levels, params, me]);

  const updateLevelId = React.useCallback((levelId: number) => setParams({ level: `${levelId}` }), [setParams]);

  return [levelId, updateLevelId] as const;
};
