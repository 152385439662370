import { MIXPANEL_TOKEN } from '@/config';

import { Analytics } from '@chew/common/services/analytics/types';
import { UserTrackerCallback } from '@chew/common/services/analytics/user-tracker';

import { EventType, EventProperties } from './event-properties';
import { UserProperties } from './user-properties';

import { MixpanelAnalytics } from './mixpanel';

class AnalyticsService implements Analytics<EventProperties, UserProperties> {
  constructor(private providers: Analytics<EventProperties, UserProperties>[]) {}

  trackUser(callback: UserTrackerCallback<UserProperties>): void {
    this.executeOnProvider((provider) => provider.trackUser(callback));
  }

  private executeOnProvider(callback: (provider: Analytics<EventProperties, UserProperties>) => void) {
    this.providers.forEach((provider) => {
      try {
        callback(provider);
      } catch (e) {
        console.error(e);
      }
    });
  }

  getCrossDomainParameters(): Record<string, string | undefined> {
    return this.providers.reduce((params, provider) => ({ ...params, ...provider.getCrossDomainParameters() }), {});
  }

  trackEvent<E extends EventType>(type: E, event: EventProperties[E], userProperties?: Partial<UserProperties>): void {
    this.executeOnProvider((provider) => provider.trackEvent(type, event, userProperties));
  }

  setCurrentUser(userId?: string): void {
    this.executeOnProvider((provider) => provider.setCurrentUser(userId));
  }
}

export const analytics = new AnalyticsService([
  ...(MIXPANEL_TOKEN ? [new MixpanelAnalytics({ token: MIXPANEL_TOKEN })] : [])
]);
