import React from 'react';

import { LessonDetail } from '@chew/common/domains';

import { getCurrentTimeFromProgress, getProgressFromCurrentTime } from '@/util/video';

interface Props {
  lesson: LessonDetail;
  progress: number;
  className?: string;
  onProgressChange(progress: number): void;
  onEnded(): void;
}

export const LessonVideoPlayer: React.FC<Props> = ({ lesson, progress, className, onProgressChange, onEnded }) => {
  const ref = React.useRef<HTMLVideoElement>(null);
  const currentProgress = React.useRef(progress);

  const onLoadedMetadata = React.useCallback(() => {
    if (!ref.current || progress === 0) return;

    // Seek into the saved position
    ref.current.currentTime = getCurrentTimeFromProgress({ progress, duration: ref.current?.duration ?? 1 });
  }, []);

  const onPlay = React.useCallback(() => {
    onProgressChange(currentProgress.current);
  }, [onProgressChange]);

  const onTimeUpdate = React.useCallback(() => {
    if (!ref.current) return;

    currentProgress.current = getProgressFromCurrentTime({
      currentTime: ref.current.currentTime,
      duration: ref.current.duration
    });
  }, []);

  const onIncrementalProgressChange = React.useCallback(() => {
    if (currentProgress.current <= progress) return;

    return onProgressChange(currentProgress.current);
  }, [onProgressChange]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      onIncrementalProgressChange();
      if (currentProgress.current >= 100) clearInterval(interval);
    }, 60 * 100);

    return () => {
      clearInterval(interval);
      onIncrementalProgressChange();
    };
  }, [onIncrementalProgressChange]);

  return (
    <video
      {...{
        ref,
        onLoadedMetadata,
        onPlay,
        onTimeUpdate,
        onEnded,
        className
      }}
      autoPlay
      controls
      width="100%"
      height="100%"
      controlsList="nodownload"
      onContextMenu={(e) => e.preventDefault()}
    >
      <source src={lesson.video?.url} type="video/mp4" />
    </video>
  );
};
