import React from 'react';
import classnames from 'classnames';
import { Menu } from '@headlessui/react';

interface Props {
  icon: string;
  className?: string;
  onClick(): void;
}

export const MenuButton: React.FC<React.PropsWithChildren<Props>> = ({ icon, className, onClick, children }) => (
  <Menu.Item
    as="div"
    {...{ onClick }}
    className={classnames(
      'px-4 flex items-center text-base text-black gap-2 cursor-pointer w-full h-10 py-2 hover:bg-gray-light transition-colors',
      className
    )}
  >
    <img src={icon} className="h-4 object-cover" />
    {children}
  </Menu.Item>
);
