import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { SUPPORTED_IMAGE_FORMATS } from '@/constants';

import { withValidation } from '@chew/common/components/hoc/withValidation';

import { FileUpload, Props as FileUploadProps } from '@/components/FileUpload';

import { useImagePreview } from '@/hooks/useImagePreview';

import placeholder from '@/assets/profile_placeholder.svg';

export interface Props extends Omit<FileUploadProps, 'type' | 'value' | 'onChange'> {
  id: string;
  name: string;
  title: string;
  invalid: boolean;
  value?: File | string;
  onChange(file?: File): void;
  onFocus(): void;
  onBlur(): void;
}

const PictureUpload = ({ value, invalid, title, disabled, className, onChange, ...rest }: Props) => {
  const { preview, updateSource } = useImagePreview(value);

  React.useEffect(() => {
    updateSource(value);
    rest.onBlur();
  }, [value]);

  const change = (files?: FileList | null) => {
    return onChange(files?.[0]);
  };

  return (
    <div className={classnames('flex items-center gap-4', className)}>
      <div
        style={{ backgroundImage: `url('${preview ?? placeholder}')` }}
        className="w-20 h-20 bg-cover aspect-square rounded-3xl border-4 border-solid border-white bg-gray-light shadow-lg"
      />

      <FileUpload
        {...rest}
        value={value}
        multiple={false}
        onChange={change}
        accept={SUPPORTED_IMAGE_FORMATS.join(',')}
        className="cursor-pointer"
      >
        <div className="rounded-full border border-solid border-purple-dark px-4 h-[42px] max-w-[150px] flex justify-center items-center">
          <FormattedMessage id={translations.inputs.buttons.photoUpload} />
        </div>
      </FileUpload>
    </div>
  );
};

export default withValidation(PictureUpload);
