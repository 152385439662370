import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { emailSchema } from '@/schemas';
import { translations } from '@/locales';
import { forgotPassword } from '@/services/api';

import { Button } from '@chew/common/components/Button';
import { Form, FormField } from '@chew/common/components/Form';
import { TextInputWithValidation } from '@chew/common/components/Input/TextInput';
import { useNavigate } from 'react-router-dom';

const schema = yup.object({
  email: emailSchema
});

export const ForgotPassword: React.FC = () => {
  const intl = useIntl();

  const navigate = useNavigate();

  return (
    <Form
      schema={schema}
      onSubmit={({ email }) => forgotPassword(email).then(() => navigate('/password-confirmation', { state: email }))}
    >
      {/* @ts-ignore */}
      {({ submitting }) => (
        <div className="flex flex-col">
          <div className="text-2xl font-extrabold text-center mb-3">
            <FormattedMessage id={translations.pages.forgotPassword.title} />
          </div>

          <div className="text-center text-purple-medium mb-6 max-w-xs self-center">
            <FormattedMessage id={translations.pages.forgotPassword.subtitle} />
          </div>

          <div className="text-header font-extrabold mb-1">
            <FormattedMessage id={translations.inputs.email.label} />
          </div>

          <FormField
            as={TextInputWithValidation}
            id="email"
            name="email"
            type="email"
            placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
          />

          <Button appearance="gradient-orange" className="mt-9 h-16" disabled={submitting}>
            <FormattedMessage id={translations.inputs.buttons.sendNewPassword} />
          </Button>
        </div>
      )}
    </Form>
  );
};
