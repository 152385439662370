import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';

import { SentryRoutes } from '@/services/sentry';
import { metaPixelAnalytics } from '@/services/analytics/meta-pixel';

import { Header } from '@/components/App/Header';
import { Session } from '@/containers/SessionContainer';

import {
  SignInPage,
  SignUpPage,
  ForgotPassword,
  ResetPasswordConfirmation,
  AuthBasePage,
  SignUpConfirmation
} from '@/pages/AuthPages';
import { DashboardPage } from '@/pages/DashboardPage';
import { CoursesListPage } from '@/pages/Courses/CourseListPage';
import { LessonDetailPage } from '@/pages/Courses/LessonDetailPage';
import { ContinueCoursePage } from '@/pages/Courses/ContinueCoursePage';
import { SubscriptionsPage } from '@/pages/SubscriptionsPage';
import { ConfirmSubscriptionPage } from '@/pages/ConfirmSubscriptionPage';

export const AppContent: React.FC = () => {
  const { authenticated, me } = Session.useContainer();
  const location = useLocation();

  React.useEffect(() => {
    if (!authenticated || !me?.premiumSubscription)
      metaPixelAnalytics.trackEvent('ViewContent', { location: location.pathname });
  }, [authenticated, location.pathname, me?.premiumSubscription]);

  return (
    <React.Fragment>
      {authenticated ? (
        <React.Fragment>
          <Header />

          <div className="relative flex flex-col pt-20 min-h-screen bg-regular">
            {!!me && (
              <SentryRoutes>
                <Route path="/dashboard" element={<DashboardPage />} />

                <Route path="/courses">
                  <Route index element={<CoursesListPage />} />
                  <Route path=":courseId" element={<ContinueCoursePage />} />
                  <Route path=":courseId/lessons/:lessonId" element={<LessonDetailPage />} />
                </Route>

                <Route path="/subscriptions/*">
                  <Route index element={<SubscriptionsPage />} />
                  <Route path="confirmation/:orderId" element={<ConfirmSubscriptionPage />} />

                  <Route path="*" element={<Navigate to="/subscriptions" replace />} />
                </Route>

                <Route path="*" element={<Navigate to="/dashboard" replace />} />
              </SentryRoutes>
            )}
          </div>
        </React.Fragment>
      ) : (
        <AuthBasePage>
          <SentryRoutes>
            <Route path="/sign-in" element={<SignInPage />} />

            <Route path="/sign-up" element={<SignUpPage />} />

            <Route path="/sign-up-confirmation" element={<SignUpConfirmation />} />

            <Route path="/forgot-password" element={<ForgotPassword />} />

            <Route path="/password-confirmation" element={<ResetPasswordConfirmation />} />

            <Route path="*" element={<Navigate to="/sign-in" replace />} />
          </SentryRoutes>
        </AuthBasePage>
      )}
    </React.Fragment>
  );
};
