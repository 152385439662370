import React from 'react';
import { range } from 'lodash';
import classnames from 'classnames';

interface Props {
  questionCount: number;
  currentQuestion: number;
}

export const ProgressBar: React.FC<Props> = ({ questionCount, currentQuestion }) => {
  return (
    <div className="flex w-full gap-2">
      {range(questionCount).map((questionNumber) => (
        <div
          key={questionNumber}
          className={classnames(
            'h-2 w-full rounded-full transition-colors duration-1000',
            questionNumber === currentQuestion && 'animate-pulse',
            questionNumber <= currentQuestion ? 'bg-orange-light' : 'bg-gray-light'
          )}
        />
      ))}
    </div>
  );
};
