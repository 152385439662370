import { Price } from './price';

export enum SubscriptionPlanType {
  Monthly = 'monthly',
  Yearly = 'yearly'
}

export interface SubscriptionPlan {
  id: string;
  type: SubscriptionPlanType;
  price: Price;
}
