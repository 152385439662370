import React from 'react';
import { useAsyncFn } from 'react-use';

import { analytics } from '@/services/analytics';
import { DigitalTask, DigitalTaskType } from '@/domains/digital-task';

import { InstanceProps } from '@chew/common/components/Modal';

import { DigitalTaskCompletedContent } from './DigitalTaskCompletedContent';
import { submitDigitalTaskAnswers } from '@/services/api/digital-tasks';
import { DigitalTaskGameContent } from './DigitalTaskGameContent';

interface Props extends InstanceProps<[]> {
  levelId: number;
  digitalTask: DigitalTask<DigitalTaskType>;
  onCompleted(): void;
}

export const DigitalTaskModal: React.FC<Props> = ({ levelId, digitalTask, onClose, onCompleted }) => {
  const [completed, setCompleted] = React.useState(false);

  const [{ value: result }, onSubmit] = useAsyncFn(async (answers: string[]) => {
    const result = await submitDigitalTaskAnswers({ levelId, answers });

    if (result.correct) onCompleted();

    return result;
  });

  React.useEffect(() => {
    analytics.trackEvent('Courses - Open task', {
      type: digitalTask.completed ? 'completed' : 'new',
      level: levelId
    });
  }, []);

  return (
    <div className="max-w-md w-full p-8">
      {completed ? (
        <DigitalTaskCompletedContent
          onClose={() => {
            analytics.trackEvent('Tasks - Collect points', {});

            onClose();
          }}
          points={result?.points ?? 0}
        />
      ) : (
        <DigitalTaskGameContent
          {...{ digitalTask, result }}
          onSubmit={(answers) => {
            analytics.trackEvent('Tasks - Submit answer', {});

            return onSubmit(answers);
          }}
          onComplete={() => setCompleted(true)}
        />
      )}
    </div>
  );
};
