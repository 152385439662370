import React from 'react';
import useSWR from 'swr';

import { getContinueWatching } from '@/util/me';
import { getCourses } from '@/services/api/courses';

import { Session } from '@/containers/SessionContainer';
import { getLessonMetadata } from '@/util/lesson';

export const useLoadCourses = (levelId?: number) => {
  const { me } = Session.useContainer();

  const continueWatching = getContinueWatching(me);

  const { data = [], isValidating: loading } = useSWR(
    levelId ? { key: `getCourses`, levelId } : false,
    ({ key, ...o }) => getCourses(o),
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  return React.useMemo(
    () => ({
      courses: data.map((course) => ({
        ...course,
        lessons: course.lessons.map((lesson) => ({
          ...lesson,
          metadata: {
            ...getLessonMetadata(continueWatching, lesson.id)
          }
        }))
      })),
      loading
    }),
    [data, loading]
  );
};
