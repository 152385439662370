import React from 'react';
import classnames from 'classnames';

interface Props extends React.HTMLProps<HTMLDivElement> {
  src: string;
  square?: boolean;
  className?: string;
}

export const LessonThumbnail: React.FC<React.PropsWithChildren<Props>> = ({
  src,
  square,
  className,
  children,
  ...props
}) => (
  <div className={classnames('relative overflow-hidden', className)} {...props}>
    <div className={classnames('relative bg-gray-100', square ? 'aspect-square' : 'aspect-video')}>
      <img {...{ src }} className="absolute w-full h-full object-cover" />
    </div>

    <div className="absolute inset-0 bg-black/30 " />

    {children}
  </div>
);
