import { CourseWithLessons, Lesson, LessonDetail } from '@chew/common/domains';
import { authorizedApi } from '@chew/common/services/network/authorized-api';

import { LessonWithMetadata } from '@/domains';
import { createFormDataFromObject } from '@/util/formData';
import { getContinueWatching } from '@/util/me';
import { getProfile } from './auth';
import { serializeLesson } from '@/util/lesson';

interface GetCoursesOptions {
  levelId: number;
}

export const getCourses = ({ levelId }: GetCoursesOptions) =>
  authorizedApi.get<{ content: CourseWithLessons[] }>(`/v1/courses`, { levelId }).then((r) => r.content);

interface GetCourseOptions {
  courseId: number;
}

export const getCourse = ({ courseId }: GetCourseOptions) =>
  authorizedApi.get<CourseWithLessons>(`/v1/courses/${courseId}`);

export const getNextLesson = (lessons: Lesson[]) => {
  return lessons.find(({ completed }) => !completed);
};

interface GetLessonOptions {
  lessonId: number;
}

export const getLesson = ({ lessonId }: GetLessonOptions) => authorizedApi.get<LessonDetail>(`/v1/lessons/${lessonId}`);

export const getCompletedLessons = () => authorizedApi.get<Lesson[]>(`/v1/lessons/completed`);

export const updateLessonProgress = async (lesson: LessonWithMetadata) => {
  if (lesson.metadata?.progress === 100) return watchLesson(lesson);

  const profile = await getProfile();

  await authorizedApi.put(
    '/user/profile',
    createFormDataFromObject({
      continueWatching: [serializeLesson(lesson), ...getContinueWatching(profile).filter(({ id }) => id != lesson.id)]
    })
  );
};

export const watchLesson = async (lesson: Lesson) => {
  const profile = await getProfile();

  await authorizedApi.put(
    '/user/profile',
    createFormDataFromObject({
      continueWatching: getContinueWatching(profile).filter(({ id }) => id != lesson.id)
    })
  );

  await authorizedApi.post('/page/lesson/watched', { lessonId: lesson.id });
  await authorizedApi.post(`/user/points`, { moduleId: lesson.id, type: 'lesson' });
};
