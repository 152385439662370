import React from 'react';
import { useSet } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { XCircleIcon } from '@heroicons/react/outline';

import { translations } from '@/locales';

import { Quiz, QuizQuestion } from '@chew/common/domains';
import { Button } from '@chew/common/components/Button';

import { ProgressBar } from './ProgressBar';
import { AnswerOptionButton } from './AnswerOptionButton';

interface Props {
  quiz: Quiz;
  currentQuestionIndex: number;
  currentQuestion: QuizQuestion;
  onAnswer(data: number[]): void;
  onClose(): void;
}

const text = translations.modals.quizModal;

export const QuizQuestions: React.FC<Props> = ({ currentQuestionIndex, quiz, currentQuestion, onAnswer, onClose }) => {
  const [answers, { add, has, remove, reset }] = useSet<number>();

  const handleAnswerInteraction = (value: number) => (has(value) ? remove(value) : add(value));

  const handleSingleSelectQuestion = (value: number) => {
    reset();
    handleAnswerInteraction(value);
  };

  return (
    <div className="flex w-full flex-col p-6 max-w-xl justify-between min-h-full">
      <div className="flex items-center w-full">
        <ProgressBar questionCount={quiz.questions.length} currentQuestion={currentQuestionIndex} />

        <XCircleIcon
          className="h-10 w-10 text-black stroke-1 hover:text-orange-light transition-colors ml-6 rounded-full"
          onClick={onClose}
        />
      </div>

      <div className="text-orange-light font-extrabold mt-6">
        <FormattedMessage
          id={text.question}
          values={{ current: currentQuestionIndex + 1, total: quiz.questions.length }}
        />
      </div>

      {currentQuestion && <div className="text-2xl font-extrabold mt-4 mb-6">{currentQuestion.question}</div>}

      <div className="flex flex-col space-y-6">
        {currentQuestion.answers.map((question) => (
          <AnswerOptionButton
            key={question.id}
            isSelected={has(question.id)}
            text={question.text}
            onClick={() => handleSingleSelectQuestion(question.id)}
          />
        ))}
      </div>

      <div className="block w-full mt-10 pb-6 xs:pb-0">
        <Button
          disabled={answers.size === 0}
          type="button"
          onClick={() => {
            onAnswer(Array.from(answers));

            reset();
          }}
          appearance="gradient-orange"
          className="h-16 w-full"
        >
          <FormattedMessage id={currentQuestionIndex === quiz.questions.length - 1 ? text.finish : text.nextQuestion} />
        </Button>
      </div>
    </div>
  );
};
