import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import { Button } from '@chew/common/components/Button';

interface Props {
  onClick(): void;
  provider: 'facebook' | 'google';
  className?: string;
}

const googleLogo = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.745 12.27C23.745 11.48 23.675 10.73 23.555 10H12.255V14.51H18.725C18.435 15.99 17.585 17.24 16.325 18.09V21.09H20.185C22.445 19 23.745 15.92 23.745 12.27Z"
      fill="#4285F4"
    />
    <path
      d="M12.255 24C15.495 24 18.205 22.92 20.185 21.09L16.325 18.09C15.245 18.81 13.875 19.25 12.255 19.25C9.12501 19.25 6.47501 17.14 5.52501 14.29H1.54501V17.38C3.51501 21.3 7.56501 24 12.255 24Z"
      fill="#34A853"
    />
    <path
      d="M5.52501 14.29C5.27501 13.57 5.145 12.8 5.145 12C5.145 11.2 5.28501 10.43 5.52501 9.71V6.62H1.545C0.725004 8.24 0.255005 10.06 0.255005 12C0.255005 13.94 0.725004 15.76 1.545 17.38L5.52501 14.29Z"
      fill="#FBBC05"
    />
    <path
      d="M12.255 4.75C14.025 4.75 15.605 5.36 16.855 6.55L20.275 3.13C18.205 1.19 15.495 0 12.255 0C7.56501 0 3.51501 2.7 1.54501 6.62L5.52501 9.71C6.47501 6.86 9.12501 4.75 12.255 4.75Z"
      fill="#EA4335"
    />
  </svg>
);

const facebookLogo = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_498_8271)">
      <path
        d="M12 24.0017C18.6274 24.0017 24 18.6291 24 12.0017C24 5.37429 18.6274 0.00170898 12 0.00170898C5.37258 0.00170898 0 5.37429 0 12.0017C0 18.6291 5.37258 24.0017 12 24.0017Z"
        fill="#1977F3"
      />
      <path
        d="M16.6711 15.4713L17.2026 12.0017H13.8748V9.75057C13.8748 8.80218 14.3389 7.87573 15.8307 7.87573H17.3444V4.92255C17.3444 4.92255 15.9707 4.68799 14.6579 4.68799C11.9173 4.68799 10.1252 6.34851 10.1252 9.35737V12.0017H7.07748V15.4713H10.1252V23.8566C10.736 23.9528 11.3621 24.0017 12 24.0017C12.6379 24.0017 13.264 23.9511 13.8748 23.8566V15.4713H16.6711Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_498_8271">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const SocialButton = ({ onClick, provider, className, children }: PropsWithChildren<Props>) => (
  <Button {...{ onClick }} type="button" appearance="app-hollow" className={classnames('h-[50px]', className)}>
    {provider === 'facebook' ? facebookLogo : googleLogo}
    <span className="ml-2 text-header">{children}</span>
  </Button>
);
