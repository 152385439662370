import React from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';

import { analytics } from '@/services/analytics';
import { LessonBadge, Quiz } from '@chew/common/domains';
import { InstanceProps } from '@chew/common/components/Modal';

import { useQuiz } from '@/hooks/useQuiz';

import { QuizQuestions } from './QuizQuestions';
import { QuizFailedNotice } from './QuizFailedNotice';
import { QuizCompletedNotice } from './QuizCompletedNotice';

interface Props extends InstanceProps<[]> {
  badge: LessonBadge;
  quiz: Quiz;
  isOpen: boolean;
  onCompleted(): void;
}

export const QuizConfettiModal: React.FC<Props> = ({ isOpen, badge, quiz, onClose, onCompleted }) => {
  const { courseId, lessonId } = useParams<{ courseId: string; lessonId: string }>();

  const { currentQuestion, currentQuestionIndex, registerAnswer, grade, clearQuiz, quizCompleted, quizPoints } =
    useQuiz(badge, quiz.questions, quiz.id, lessonId!, courseId!, () => {
      onCompleted();
    });

  const { width, height } = useWindowSize();

  React.useEffect(() => {
    if (isOpen) analytics.trackEvent('Quiz - Take quiz', {});

    return () => {
      clearQuiz();
    };
  }, [isOpen]);

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" static onClose={() => null} className="fixed z-50 inset-0 overflow-y-auto">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/30" />
        </Transition.Child>

        {quizCompleted && grade > 80 && <Confetti {...{ height, width }} />}

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full w-full items-center justify-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative xs:m-6 flex w-full max-w-xl bg-white xs:rounded-3xl xs:mx-4 h-screen xs:h-auto overflow-y-scroll">
                {quizCompleted ? (
                  <div className="w-full flex">
                    {grade < 80 ? (
                      <QuizFailedNotice {...{ grade }} onClick={() => onClose()} />
                    ) : (
                      <QuizCompletedNotice
                        quizXp={quizPoints}
                        onClick={() => {
                          analytics.trackEvent('Quiz - Click collect points', {});
                          onClose();
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <QuizQuestions
                    {...{ quiz, currentQuestion, currentQuestionIndex, onClose }}
                    onClose={() => {
                      analytics.trackEvent('Quiz - Cancel quiz', {
                        type: 'cancel',
                        answeredQuestions: currentQuestionIndex
                      });

                      onClose();
                    }}
                    onAnswer={(answers) => registerAnswer(Array.from(answers))}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
