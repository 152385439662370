import React from 'react';
import { Routes, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { SENTRY_DSN, ENVIRONMENT, RELEASE } from '@/config';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: RELEASE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  normalizeDepth: 10,
  autoSessionTracking: true,
  tracesSampleRate: 1.0
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
