import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useLoadCourse } from './hooks/useLoadCourse';

export const ContinueCoursePage: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();

  const { data: course, error } = useLoadCourse(+courseId!);

  if (error) return <Navigate to="/courses" replace />;

  if (!course) return null;

  const lessonId = course.lessons[0]?.id;

  if (!lessonId) return <Navigate to="/courses" replace />;

  return <Navigate to={`/courses/${courseId}/lessons/${lessonId}`} replace />;
};
