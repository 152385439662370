import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dialog, Transition } from '@headlessui/react';

import { translations } from '@/locales';

import { Session } from '@/containers/SessionContainer';

import { Button } from '@chew/common/components/Button';
import { InstanceProps } from '@chew/common/components/Modal';

import christerQuiz from '@/assets/christier-quiz.png';

const text = translations.modals.quizNoticeModal;

interface Props extends InstanceProps<[]> {
  isOpen: boolean;
}

export const QuizNoticeModal: React.FC<Props> = ({ isOpen, onAction, onClose }) => {
  const { me } = Session.useContainer();

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" static {...{ onClose }} className="fixed z-50 inset-0 overflow-y-auto">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-end justify-end">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative bg-transparent flex flex-col">
                <div className="rounded-3xl bg-white p-6 md:-ml-72 md:absolute mr-10 mb-4">
                  <div className="text-lg font-extrabold">
                    <FormattedMessage id={text.title} values={{ name: me?.firstName }} />
                  </div>

                  <div className="text-2xl font-extrabold">
                    <FormattedMessage id={text.subtitle} />
                  </div>

                  <div className="min-w-[220px] mt-3">
                    <Button
                      appearance="gradient-orange"
                      className="w-full h-16"
                      onClick={() => {
                        onAction();
                        onClose();
                      }}
                    >
                      <FormattedMessage id={text.button} />
                    </Button>
                  </div>
                </div>

                <img src={christerQuiz} className="max-w-[200px] ml-auto" onClick={() => onClose()} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
