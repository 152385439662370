import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Button } from '@chew/common/components/Button';
import { InstanceProps } from '@chew/common/components/Modal';

interface Props extends InstanceProps<[]> {}

const text = translations.modals.unavailableModal;

export const UnavailableModal: React.FC<Props> = ({ onClose }) => (
  <div className="max-w-md w-full p-8 space-y-6">
    <div className="text-3xl font-extrabold text-center">
      <FormattedMessage id={text.title} />
    </div>

    <div className="text-lg text-purple-medium">
      <FormattedMessage id={text.message} />
    </div>

    <div>
      <Button appearance="gradient-orange" className="w-full h-16" onClick={onClose}>
        <FormattedMessage id={text.button} />
      </Button>
    </div>
  </div>
);

const lockedText = translations.modals.lockedtaskModal;

export const LockedTaskModal: React.FC<Props> = ({ onClose }) => (
  <div className="max-w-md w-full p-8 space-y-6">
    <div className="text-3xl font-extrabold text-center">
      <FormattedMessage id={lockedText.title} />
    </div>

    <div className="text-lg text-purple-medium">
      <FormattedMessage id={lockedText.message} />
    </div>

    <div>
      <Button appearance="gradient-orange" className="w-full h-16" onClick={onClose}>
        <FormattedMessage id={lockedText.button} />
      </Button>
    </div>
  </div>
);
