import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import '@/services/sentry';

import { ErrorBoundary } from '@/components/ErrorBoundary';
import { LanguageContainer } from '@/components/Context/LanguageContext';

import { App } from './App';

const app = document.getElementById('app');

if (!app) throw new Error('No root element found');

const root = createRoot(app);

root.render(
  <LanguageContainer.Provider>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </LanguageContainer.Provider>
);
