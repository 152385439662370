import { parsePosibleJSONString } from '@/util/me';
import { createFormDataFromObject } from '@/util/formData';

import { LegacyBadge, LessonBadge } from '@chew/common/domains';
import { authorizedApi } from '@chew/common/services/network/authorized-api';

import { getProfile } from './auth';

interface FinishQuizOptions {
  lessonId: string;
  quizId: string;
  courseId: string;
}

interface CompleteQuizOptions extends FinishQuizOptions {
  grade: number;
  badge: LessonBadge;
}

export const finishQuiz = ({ lessonId, quizId, courseId }: FinishQuizOptions) =>
  authorizedApi.post(`/quiz/finished`, { lessonId, quizId, courseId });

export const sendQuizEvaluation = (moduleId: number, type: 'quiz' | 'quizPart') =>
  authorizedApi
    .post<{ data: { points: number } }>('/user/points', { moduleId, type })
    .then((response) => response.data.points);

const convertBadgeToLegacy = (badge: LessonBadge | LegacyBadge): LegacyBadge => {
  if (isBadgeLegacy(badge)) return badge;

  return {
    id: badge.id,
    imageSrc: badge.image.url,
    name: badge.name
  };
};

const isBadgeLegacy = (badge: LessonBadge | LegacyBadge): badge is LegacyBadge => !!(badge as LegacyBadge)['imageSrc'];

const addBadge = async (badge: LessonBadge) => {
  const profile = await getProfile();

  const badges: Array<LegacyBadge | LessonBadge> = parsePosibleJSONString(profile.earnedBadges, []);

  if (badges.some((item) => item.id === badge.id)) {
    return;
  }

  const legacyBadges = [...badges, badge].map(convertBadgeToLegacy);

  await authorizedApi.put<{ earnedBadges: string }>(
    '/user/profile',
    createFormDataFromObject({
      earnedBadges: JSON.stringify(legacyBadges)
    })
  );
};

export const completeQuiz = async ({ lessonId, quizId, courseId, grade, badge }: CompleteQuizOptions) => {
  await finishQuiz({ lessonId, quizId, courseId });

  await addBadge(badge);

  const points = await sendQuizEvaluation(parseInt(quizId), grade === 100 ? 'quiz' : 'quizPart');

  return points;
};
