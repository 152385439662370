import React from 'react';
import classnames from 'classnames';
import { IntlShape, useIntl } from 'react-intl';

import { HttpError } from '@chew/common/services/network/http';

import { translations } from '@/locales';

interface Props {
  error: HttpError<any> | undefined | null;
  className?: string;
}

const errorMap = {
  invalid_credentials_error: 'invalid_grant'
};

const extractMessage = (intl: IntlShape, code: string) => {
  // @ts-ignore
  const normalizedCode = translations.errors.api[code] ?? translations.errors.api[errorMap[code]];

  const messageId = normalizedCode ?? translations.errors.custom.unknown;

  return intl.formatMessage({ id: messageId });
};

const extractMessages = (intl: IntlShape, error: HttpError<any>): string[] => {
  if (!error.isAxiosError) return [error.message];

  if (!error.response?.data?.errors?.length) {
    if (!error.response?.data?.code)
      return [
        error.response?.data?.message ?? error.message ?? intl.formatMessage({ id: translations.errors.custom.unknown })
      ];

    return [extractMessage(intl, (error.response.data.code as string).toLowerCase())];
  }

  return error.response.data.errors.map(({ code }: { code: string }) => extractMessage(intl, code));
};

export const SubmitError: React.FC<React.PropsWithChildren<Props>> = ({ error, children, className }) => {
  const intl = useIntl();

  if (!error) return null;

  const validErrors = extractMessages(intl, error).filter((message) => !!message);

  return (
    <React.Fragment>
      <div className={classnames('inline-block p-2 text-left rounded-2xl text-red-700', className)}>
        {validErrors.length > 0
          ? validErrors.map((error, index) => <div key={index}>{error}</div>)
          : intl.formatMessage({ id: translations.errors.custom.unknown })}
      </div>

      {children}
    </React.Fragment>
  );
};
