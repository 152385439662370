import React from 'react';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { SocialAuthIntent } from '@/services/api';
import { emailSchema, passwordSchema } from '@/schemas';

import { Button } from '@chew/common/components/Button';
import { Form, FormField } from '@chew/common/components/Form';
import { TextInputWithValidation } from '@chew/common/components/Input/TextInput';

import { SubmitError } from '@/components/Error';
import { GoogleButton, FacebookButton } from '@/components/Social';
import { Session } from '@/containers/SessionContainer';
import { analytics } from '@/services/analytics';

const schema = yup.object({
  email: emailSchema,
  password: passwordSchema
});

export const SignInPage: React.FC = () => {
  const intl = useIntl();
  const [error, setError] = React.useState<Error>();

  const { loginWithEmailAndPassword } = Session.useContainer();

  return (
    <Form schema={schema} onSubmit={({ email, password }) => loginWithEmailAndPassword({ email, password })}>
      {({ submitting, submitError }) => (
        <div className="flex flex-col">
          <div className="text-3xl font-extrabold text-center mb-6">
            <FormattedMessage id={translations.pages.signIn.title} />
          </div>

          <GoogleButton intent={SocialAuthIntent.Login} onError={setError} className="mb-2">
            <FormattedMessage id={translations.inputs.buttons.google.signIn} />
          </GoogleButton>

          <FacebookButton intent={SocialAuthIntent.Login} onError={setError}>
            <FormattedMessage id={translations.inputs.buttons.facebook.signIn} />
          </FacebookButton>

          <div className="flex items-center my-4">
            <div className="h-[1px] bg-gray-light w-full" />
            <span className="text-purple-medium mx-2">or</span>
            <div className="h-[1px] bg-gray-light w-full" />
          </div>

          <div className="text-header font-extrabold mb-1">
            <FormattedMessage id={translations.inputs.email.label} />
          </div>

          <FormField
            as={TextInputWithValidation}
            id="email"
            name="email"
            type="email"
            placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
          />

          <div className="text-header font-extrabold mt-2 mb-1">
            <FormattedMessage id={translations.inputs.password.label} />
          </div>

          <FormField
            as={TextInputWithValidation}
            id="password"
            name="password"
            type="password"
            placeholder={intl.formatMessage({ id: translations.inputs.password.placeholder })}
          />

          <SubmitError error={error ?? submitError} />

          <Button appearance="gradient-orange" type="submit" className="mt-9 h-16" disabled={submitting}>
            <FormattedMessage id={translations.inputs.buttons.signIn} />
          </Button>

          <div className="text-sm text-purple-medium text-center mt-4">
            <FormattedMessage
              id={translations.pages.signIn.signUpRedirect}
              values={{
                span: ((text: string) => (
                  <Link
                    to="/sign-up"
                    className="text-orange-light underline"
                    onClick={() => analytics.trackEvent('Auth - Switch to sign up', {})}
                  >
                    {text}
                  </Link>
                )) as unknown as React.ReactNode
              }}
            />
          </div>

          <div className="text-sm text-purple-medium text-center mt-4">
            <FormattedMessage
              id={translations.pages.signIn.forgotPasswordRedirect}
              values={{
                span: ((text: string) => (
                  <Link
                    to="/forgot-password"
                    className="text-orange-light underline"
                    onClick={() => analytics.trackEvent('Auth - Reset password', {})}
                  >
                    {text}
                  </Link>
                )) as unknown as React.ReactNode
              }}
            />
          </div>
        </div>
      )}
    </Form>
  );
};
