import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LockClosedIcon, PlayIcon, CheckIcon } from '@heroicons/react/solid';
import classnames from 'classnames';

import { LessonLockType } from '@chew/common/domains';
import { translations } from '@/locales';

interface LessonLockedOverlayProps {
  lock: LessonLockType;
  small?: boolean;
}

export const LessonLockedOverlay: React.FC<LessonLockedOverlayProps> = ({ lock, small = false }) => (
  <div
    className={classnames(
      'absolute center inline-flex items-center font-medium text-white bg-white/20 rounded-full backdrop-blur whitespace-nowrap',
      { 'px-4 py-2': !small, 'w-6 h-6': small }
    )}
  >
    <LockClosedIcon className={classnames({ 'absolute center w-4 h-4': small, 'w-5 h-5': !small })} />
    {!small && (
      <div className="ml-2">
        <FormattedMessage
          id={
            lock === LessonLockType.Subscription
              ? translations.domains.lesson.paywall
              : translations.domains.lesson.unavailable
          }
        />
      </div>
    )}
  </div>
);

interface LessonPlayOverlayProps {
  circleClassName?: string;
}

export const LessonPlayOverlay: React.FC<LessonPlayOverlayProps> = ({ circleClassName }) => (
  <div className="absolute center text-orange-light after:content-[''] after:absolute after:center after:w-6 after:h-6 after:rounded-full after:bg-white after:-z-10">
    <PlayIcon className="w-16 h-16" />

    <div
      className={classnames(
        'absolute -inset-4 bg-orange-light/20 rounded-full -z-10 scale-50 transition-transform ease-in-out duration-300',
        circleClassName
      )}
    />
  </div>
);

export const LessonWatchedOverlay: React.FC = () => (
  <div className="absolute center w-6 h-6 rounded-full text-white bg-[#58C977]">
    <CheckIcon className="absolute center w-4 h-4" />
  </div>
);

interface LessonProgressProps {
  progress: number;
}

export const LessonProgress: React.FC<LessonProgressProps> = ({ progress }) => (
  <div
    className="absolute left-0 bottom-0 h-1 rounded-full bg-orange-light"
    style={{ width: `${Math.min(progress, 100)}%` }}
  />
);
