import React from 'react';
import * as yup from 'yup';
import { XIcon } from '@heroicons/react/solid';
import { FormattedMessage, useIntl } from 'react-intl';

import { InstanceProps } from '@chew/common/components/Modal';
import { Button } from '@chew/common/components/Button';
import { Form, FormField } from '@chew/common/components/Form';
import { TextInputWithValidation } from '@chew/common/components/Input/TextInput';

import { translations } from '@/locales';

import { Card } from '@/components/Card';

export interface Props extends InstanceProps<[{ code: string }]> {}

const schema = yup
  .object({
    code: yup.string().required().min(2).max(64).label('code')
  })
  .required();

const text = translations.modals.discountCodeModal;

export const DiscountCodeModal: React.FC<Props> = ({ onClose, onAction }) => {
  const intl = useIntl();

  return (
    <Card className="border-0 w-30 md:w-[26rem]">
      <Card.Row className="flex items-center justify-between">
        <h1 className="text-xl font-bold">
          <FormattedMessage id={text.title} />
        </h1>

        <XIcon
          className="h-4 w-4 fill-purple-medium hover:cursor-pointer hover:fill-orange-light transition-colors self-start"
          onClick={onClose}
        />
      </Card.Row>

      <Card.Row>
        <Form id="discount-code-form" onSubmit={onAction} {...{ schema }}>
          {({ id, submitting }) => (
            <React.Fragment>
              <label htmlFor={`${id}-code`} className="text-sm font-bold text-purple-medium inline-block mb-2">
                <FormattedMessage id={translations.inputs.discountCode.label} />
              </label>

              <FormField
                as={TextInputWithValidation}
                id={`${id}-code`}
                name="code"
                placeholder={intl.formatMessage({ id: translations.inputs.discountCode.placeholder })}
              />

              <Button
                className="h-16 font-bold w-full mt-11"
                appearance="gradient-orange"
                form={id}
                type="submit"
                disabled={submitting}
              >
                <FormattedMessage id={text.button} />
              </Button>
            </React.Fragment>
          )}
        </Form>
      </Card.Row>
    </Card>
  );
};
