import React from 'react';
import classnames from 'classnames';

export interface Props {
  className?: string;
}

export const CardRow: React.FC<React.PropsWithChildren<Props>> = ({ className, ...rest }) => {
  return (
    <div
      className={classnames('px-5 py-3 md:px-8 md:py-6 border-b border-solid border-gray-200 last:border-0', className)}
      {...rest}
    />
  );
};
