import React from 'react';

export const useImagePreview = (initialSource?: File | string) => {
  const [source, setSource] = React.useState(initialSource);
  const [preview, setPreview] = React.useState<File | string>();

  const updateSource = React.useCallback(
    (source?: File | string) => {
      setSource(source);
    },
    [source]
  );

  React.useEffect(() => {
    if (source) {
      if (typeof source === 'string') {
        setPreview(source);
      } else {
        const reader = new FileReader();
        reader.onload = (event) => setPreview(event?.target?.result?.toString() as any);
        reader.readAsDataURL(source);
      }
    } else {
      setPreview(undefined);
    }
  }, [source]);

  return { preview, updateSource };
};
