import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Button } from '@chew/common/components/Button';

import { Session } from '@/containers/SessionContainer';

interface Props {
  quizXp: number;
  onClick(): void;
}

const text = translations.modals.quizCompletedModal;

export const QuizCompletedNotice: React.FC<Props> = ({ quizXp, onClick }) => {
  const { me } = Session.useContainer();

  return (
    <div className="flex flex-col w-full items-center py-6">
      <div className="text-orange-light font-extrabold uppercase mb-4">
        <FormattedMessage id={text.title} />
      </div>

      <div className="text-3xl font-extrabold mb-4">
        <FormattedMessage id={text.subtitle} values={{ name: me?.firstName }} />
      </div>

      <div className="text-3xl font-extrabold ">
        <FormattedMessage id={text.score} />
      </div>

      <div className="text-7xl font-extrabold uppercase">{`${quizXp} XP`}</div>

      <Button type="button" {...{ onClick }} appearance="gradient-orange" className="mt-5 px-8 h-16 min-w-[200px]">
        <FormattedMessage id={text.continue} />
      </Button>
    </div>
  );
};
