import React from 'react';
import copy from 'copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { ClipboardCopyIcon, MailIcon } from '@heroicons/react/solid';

import { translations } from '@/locales';
import { analytics } from '@/services/analytics';
import { sendRecipeToEmail } from '@/services/api';

import { LessonDetail } from '@chew/common/domains';
import { Button } from '@chew/common/components/Button';

interface Props extends React.HTMLProps<HTMLElement> {
  lesson: LessonDetail;
}

export const IngredientSection: React.FC<Props> = ({ lesson, ...props }) => {
  const intl = useIntl();

  const clipboardText = React.useMemo(() => {
    return lesson.ingredients.reduce(
      (list, ingredient) =>
        list + (ingredient.amount ? `${ingredient.name} - ${ingredient.amount}\n` : `${ingredient.name}\n`),
      ''
    );
  }, [lesson]);

  return (
    <section {...props}>
      <h2 className="pb-6 text-2xl font-bold hidden lg:block">
        <FormattedMessage id={translations.pages.lessonDetail.sections.ingredients.title} />
      </h2>

      <ul className="space-y-2">
        {lesson.ingredients.map((ingredient, index) => (
          <li key={index} className="font-bold">
            {[ingredient.amount, ingredient.name].filter((item) => item).join(' ')}
          </li>
        ))}
      </ul>

      <Button
        appearance="orange-hollow"
        className="mt-6 px-6 h-16 text-orange-light"
        onClick={() => {
          sendRecipeToEmail(lesson.id.toString()).then(() =>
            analytics.trackEvent('Lesson - Share shopping list', { type: 'email' })
          );
        }}
      >
        <FormattedMessage id={translations.inputs.buttons.shareIngredientList} />

        <MailIcon className="h-6 ml-1" />
      </Button>

      <Toaster
        position="top-right"
        containerClassName="mt-20"
        toastOptions={{
          className: 'p-4 rounded-2xl font-bold shadow'
        }}
      />

      <Button
        appearance="orange-hollow"
        className="mt-6 px-6 h-16 text-orange-light"
        onClick={() => {
          copy(clipboardText);

          toast.dismiss();
          toast(intl.formatMessage({ id: translations.inputs.buttons.copiedToClipboard }));

          analytics.trackEvent('Lesson - Share shopping list', { type: 'copy' });
        }}
      >
        <FormattedMessage id={translations.inputs.buttons.copyToClipboard} />

        <ClipboardCopyIcon className="h-6 ml-1" />
      </Button>
    </section>
  );
};
