import { api } from '@chew/common/services/network/api';

import { SubscriptionPlan } from '@/domains';

export interface GetSubscriptionPlansResponse {
  content: SubscriptionPlan[];
}

export const getSubscriptionPlans = () => {
  return api.get<GetSubscriptionPlansResponse>(`/v1/subscription-plans`);
};
