import React from 'react';
import { useIntl } from 'react-intl';
import { translations } from '@/locales';

import { GoogleLogin as GLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

import { GOOGLE_CLIENT_ID } from '@chew/common/config';

import { SocialAuthIntent } from '@/services/api';

import { Session } from '@/containers/SessionContainer';

import { SocialButton } from './SocialButton';

interface Props {
  intent: SocialAuthIntent;
  className?: string;
  onError(error: Error): void;
}

const isGoogleLoginResponse = (
  response: GoogleLoginResponse | GoogleLoginResponseOffline
): response is GoogleLoginResponse => !!(response as GoogleLoginResponse).profileObj;

export const GoogleButton = ({ intent, className, children, onError }: React.PropsWithChildren<Props>) => {
  const intl = useIntl();

  const { socialAuth } = Session.useContainer();
  const onSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (!isGoogleLoginResponse(response)) return;

    socialAuth(intent, {
      oauth_client: 'google',
      oauth_client_id: response.profileObj.googleId,
      oauth_client_picture: response.profileObj.imageUrl,
      email: response.profileObj.email,
      firstName: response.profileObj.givenName,
      lastName: response.profileObj.familyName,
      fullName: response.profileObj.name
    });
  };

  const onFailure = () => {
    onError(new Error(intl.formatMessage({ id: translations.errors.api.social_login })));
  };

  return (
    <GLogin
      clientId={GOOGLE_CLIENT_ID}
      autoLoad={false}
      cookiePolicy="none"
      onSuccess={onSuccess}
      onFailure={onFailure}
      render={(renderProps) => (
        <SocialButton className={className} onClick={renderProps.onClick} provider="google">
          {children}
        </SocialButton>
      )}
    />
  );
};
