import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsync } from 'react-use';

import { TagIcon, CreditCardIcon, GiftIcon } from '@heroicons/react/solid';
import { translations } from '@/locales';
import { renderSnippet } from '@/util/klarna';
import { analytics } from '@/services/analytics';
import { Currency, SubscriptionPlanType } from '@/domains';
import { getSubscriptionPlans } from '@/services/api';
import { getHomePage } from '@/services/api/page';
import { metaPixelAnalytics } from '@/services/analytics/meta-pixel';
import { applyPromocode, createOrder as createOrderApi } from '@/services/api/payments';

import { Button } from '@chew/common/components/Button';
import { BenefitItem } from '@chew/common/components/BenefitItem';
import { Modal, useModal } from '@chew/common/components/Modal';

import { Card } from '@/components/Card';
import { SubscriptionPlan } from '@/components/SubscriptionPlan';
import { PagePadding } from '@/components/Page';
import { RoundedIcon } from '@/components/RoundedIcon';
import { EditButton } from '@/components/EditButton';
import { DiscountCodeModal } from '@/components/DiscountCodeModal';
import { ApplyDiscountCodeButton } from '@/components/ApplyDiscountCodeButton';

import { ActionType, reducer } from './reducer';

const text = translations.pages.subscriptions;

enum View {
  ChoosePlan,
  Checkout
}

const getDiscountPercentage = (oldAmount: number, newAmount: number) => {
  return 100 - Math.floor((newAmount * 100) / oldAmount);
};

const KLARNA_CHECKOUT_CONTAINER_ID = 'klarna-checkout-container';

export const SubscriptionsPage: React.FC = () => {
  const [view, setView] = React.useState<View>(View.ChoosePlan);
  const { value: page, loading } = useAsync(() => getHomePage(), []);

  const intl = useIntl();

  const [value, dispatch] = React.useReducer(reducer, {});
  const discountCodeModal = useModal(DiscountCodeModal);
  React.useEffect(() => {
    analytics.trackEvent('Payment - Initiated', {});
    getSubscriptionPlans().then((response) => {
      dispatch({ type: ActionType.SubscriptionPlansLoaded, plans: response.content });
    });
  }, []);

  React.useEffect(() => {
    if (!value?.htmlSnippet) return;

    renderSnippet(value?.htmlSnippet, KLARNA_CHECKOUT_CONTAINER_ID);
  }, [value?.htmlSnippet, value?.amount]);

  React.useEffect(() => {
    if (view !== View.Checkout) return;

    metaPixelAnalytics.trackEvent('InitiateCheckout', {});
  }, [view]);

  return (
    <PagePadding className="py-6 md:py-12">
      <h1 className="font-bold text-xl mb-1 md:mb-2">
        <FormattedMessage id={text.title} />
      </h1>

      <p className="text-gray-500 max-w-2xl mb-6">{!loading && page?.page.welcome.paymentText}</p>

      <Card className="mb-4">
        <Card.Row className="flex items-center justify-between">
          <div className="flex items-center">
            <RoundedIcon color="orange-light" className="w-12 h-12 min-w-[3rem]">
              <TagIcon className="w-4 h-4" />
            </RoundedIcon>

            <h2 className="font-bold text-2xl md:text-[2rem]">
              <FormattedMessage id={text.plans.title} />

              {view === View.Checkout && value.selectedPlan && (
                <div className="text-sm text-purple-medium md:text-base">
                  <FormattedMessage
                    id={translations.miscellaneous.typeMembership}
                    values={{
                      type: intl.formatMessage({
                        id: translations.domains.subscriptionPlanType[value.selectedPlan.type]
                      }),
                      value: value.selectedPlan.price.value,
                      currency: value.selectedPlan.price.currency
                    }}
                  />
                </div>
              )}
            </h2>
          </div>

          {view === View.Checkout && (
            <EditButton
              className="ml-2"
              onClick={() => {
                setView(View.ChoosePlan);
              }}
            />
          )}
        </Card.Row>

        {view === View.ChoosePlan && (
          <Card.Row>
            {value.selectedPlan && (
              <React.Fragment>
                <div className="flex flex-wrap -m-2 mb-6">
                  {value.subscriptionPlans?.map((plan) => (
                    <SubscriptionPlan
                      key={plan.id}
                      {...(plan.type === SubscriptionPlanType.Yearly && {
                        savings: { value: 125, currency: Currency.NOK }
                      })}
                      onClick={(plan) => {
                        dispatch({ type: ActionType.SubscriptionPlanSelectionChanged, plan });
                      }}
                      selected={plan.id === value.selectedPlan?.id}
                      className="m-2  md:max-w-[47%]"
                      {...{ plan }}
                    />
                  ))}
                </div>

                <h6 className="font-bold mb-3">
                  <FormattedMessage id={text.plans.benefits.title} />
                </h6>

                <div className="mb-8">
                  {Object.values(text.plans.benefits.items).map((benefit, index) => (
                    <BenefitItem key={index} text={intl.formatMessage({ id: benefit })} className="my-3" />
                  ))}
                </div>

                <Button
                  appearance="gradient-orange"
                  className="px-12 font-bold text-lg"
                  onClick={() => {
                    if (!value.selectedPlan) return;

                    analytics.trackEvent('Payment - Continue to checkout', {
                      plan: value.selectedPlan.type === SubscriptionPlanType.Yearly ? 'yearly' : 'monthly'
                    });

                    createOrderApi(value.selectedPlan.id)
                      .then((response) => {
                        const { html_snippet, order_id } = response;

                        dispatch({
                          type: ActionType.OrderCreated,
                          htmlSnippet: html_snippet,
                          orderId: order_id
                        });
                      })
                      .then(() => {
                        setView(View.Checkout);
                      });
                  }}
                >
                  <FormattedMessage id={text.button} />
                </Button>
              </React.Fragment>
            )}
          </Card.Row>
        )}
      </Card>

      <Card className="mb-4">
        <Card.Row className="flex items-center justify-between">
          <div className="flex items-center ">
            <RoundedIcon color="orange-light" className="w-12 h-12 min-w-[3rem]">
              <CreditCardIcon className="w-4 h-4" />
            </RoundedIcon>

            <h2 className="font-bold text-2xl md:text-[2rem]">
              <FormattedMessage id={text.checkout.title} />
            </h2>
          </div>

          {view === View.Checkout && !value.promocode ? (
            <ApplyDiscountCodeButton
              onClick={() => {
                discountCodeModal.open(({ code: promocode }) => {
                  if (!value.orderId) return;

                  applyPromocode({ orderId: value?.orderId, promocode })
                    .then((response) => {
                      dispatch({ type: ActionType.DiscountApplied, ...response });
                    })
                    .then(() => discountCodeModal.props.onClose());
                });
              }}
            >
              <FormattedMessage id={text.checkout.applyDiscountCode} />
            </ApplyDiscountCodeButton>
          ) : (
            value.selectedPlan?.price &&
            value.amount !== undefined && (
              <div className="flex items-center text-orange-light bg-orange-200 font-bold rounded-xl px-2 py-1">
                <GiftIcon className="w-4 h-5 fill-orange-light" />

                <span className="ml-1">
                  <FormattedMessage
                    id={text.checkout.percentageDiscountApplied}
                    values={{ percentage: getDiscountPercentage(value.selectedPlan?.price.value, value.amount) }}
                  />
                </span>
              </div>
            )
          )}
        </Card.Row>

        {view === View.Checkout && value && (
          <div>
            <div id={KLARNA_CHECKOUT_CONTAINER_ID} />
          </div>
        )}

        {value?.orderId && <Modal {...discountCodeModal.props} />}
      </Card>

      <p className="text-gray-400">
        <FormattedMessage id={text.appendix} />
      </p>
    </PagePadding>
  );
};
