import React from 'react';
import classnames from 'classnames';

import { Level } from '@chew/common/domains';
import { Is, IsComponent, IsProps } from '@chew/common/components/Is';

interface LevelFiltersProps {
  id?: number;
  levels: Level[];
  className?: string;
  onChange(id: number): void;
}

export const LevelFilters: React.FC<LevelFiltersProps> = ({ id, levels, className, onChange }) => (
  <ul className={classnames('flex space-x-2', className)}>
    {levels.map((item) => (
      <li key={item.id}>
        <LevelFilter is="button" active={item.id === id} onClick={() => onChange(item.id)}>
          {item.title}
        </LevelFilter>
      </li>
    ))}
  </ul>
);

interface LevelFilterProps {
  active: boolean;
}

export const LevelFilter = <T extends IsComponent>({
  active,
  ...props
}: React.PropsWithChildren<LevelFilterProps & IsProps<T>>) => (
  <Is
    {...props}
    className={classnames('px-6 py-2 border rounded-full font-bold', {
      'text-purple-medium bg-white border-purple-medium': !active,
      'text-white bg-orange-light border-orange-light': active
    })}
  />
);
