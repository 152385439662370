import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

type StateType = 'xp' | 'level' | 'completedLessons';

interface Props {
  className?: string;
  type: StateType;
  value: number;
}

const itemTypeToStyling: Record<
  StateType,
  { dropShadow: string; valueColor: string; valueBgColor: string; nameBgColor: string; nameColor: string }
> = {
  xp: {
    dropShadow: 'drop-shadow-stat-xp',
    valueColor: '#5D4E04',
    valueBgColor: '#F6D738',
    nameBgColor: '#F6D738',
    nameColor: '#5D4E04'
  },
  level: {
    dropShadow: 'drop-shadow-stat-level',
    valueColor: '#1A5129',
    valueBgColor: '#58C977',
    nameBgColor: '#58C977',
    nameColor: '#1A5129'
  },
  completedLessons: {
    dropShadow: 'drop-shadow-stat-completedLessons',
    valueColor: '#A5E9E9',
    valueBgColor: '#1E7B7B',
    nameBgColor: '#A5E9E9',
    nameColor: '#1E7B7B'
  }
};

export const DashboardStatItem = ({ className, type, value }: Props) => {
  return (
    <div className={classnames('flex items-center', className)}>
      <div className="relative flex items-center justify-center">
        <svg
          viewBox="0 0 41 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classnames('h-11', itemTypeToStyling[type].dropShadow)}
        >
          <path
            d="M1.04907e-06 26C4.69687e-07 32.6274 5.37258 38 12 38L25.9094 38C33.2993 38 38.933 31.3848 37.7563 24.0892L35.5886 10.6492C34.4841 3.80129 27.8208 -0.686708 21.0598 0.863512L9.31812 3.55576C3.86545 4.806 2.47773e-06 9.65806 1.98867e-06 15.2522L1.04907e-06 26Z"
            fill={itemTypeToStyling[type].valueBgColor}
          />
        </svg>

        <div className="absolute text-base font-extrabold" style={{ color: itemTypeToStyling[type].valueColor }}>
          {value}
        </div>
      </div>

      <div
        className={classnames('pl-6 pr-4 py-2 flex items-center rounded-r-full text-sm -ml-4 font-bold')}
        style={{
          backgroundColor: itemTypeToStyling[type].nameBgColor,
          color: itemTypeToStyling[type].nameColor
        }}
      >
        <FormattedMessage id={translations.pages.dashboard.stats.items[type]} />
      </div>
    </div>
  );
};
