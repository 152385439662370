import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

const items = [
  { name: translations.pages.lessonDetail.sections.summary.title, id: '' },
  { name: translations.pages.lessonDetail.sections.lessons.title, id: '#lessons' },
  { name: translations.pages.lessonDetail.sections.steps.title, id: '#recipe' },
  { name: translations.pages.lessonDetail.sections.ingredients.title, id: '#ingredients' }
];

export const NavigationSection: React.FC<React.HTMLProps<HTMLElement>> = (props) => {
  const { hash } = useLocation();

  return (
    <section {...props}>
      <ul className="flex justify-between -mx-2">
        {items.map((item) => (
          <li key={item.id}>
            <Link
              to={{ hash: item.id }}
              className={classnames('block p-2 font-bold', { 'text-orange-light': item.id == hash })}
            >
              <FormattedMessage id={item.name} />
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};
