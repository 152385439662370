import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Button } from '@chew/common/components/Button';
import { InstanceProps } from '@chew/common/components/Modal';

interface Props extends InstanceProps<[]> {}

const text = translations.modals.paywallModal;

export const PaywallModal: React.FC<Props> = ({ onAction }) => (
  <div className="max-w-md w-full p-8 space-y-6">
    <div className="text-3xl font-extrabold text-center">
      <FormattedMessage id={text.title} />
    </div>

    <div className="text-lg text-purple-medium">
      <FormattedMessage id={text.message} />
    </div>

    <div>
      <Button is={Link} to="/subscriptions" appearance="gradient-orange" className="w-full h-16" onClick={onAction}>
        <FormattedMessage id={text.button} />
      </Button>
    </div>
  </div>
);
