import { people, get_distinct_id } from 'mixpanel-browser';

import { FilteredProps, UserTracker } from '@chew/common/services/analytics/user-tracker';

import { UserProperties } from '../user-properties';

export class MixpanelUserTracker implements UserTracker<UserProperties> {
  private getValidValue(value: UserProperties[keyof UserProperties]) {
    return value;
  }

  set<T extends keyof UserProperties>(key: T, value: UserProperties[T]): this {
    if (['name', 'email', 'avatar'].includes(key)) people.set(`$${key}`, this.getValidValue(value));
    else people.set(key, this.getValidValue(value));

    return this;
  }

  setOnce<T extends keyof UserProperties>(key: T, value: UserProperties[T]): this {
    people.set_once(key, this.getValidValue(value));
    return this;
  }

  increment<T extends keyof FilteredProps<UserProperties, number>>(key: T, value: UserProperties[T]): this {
    people.increment(key, value);
    return this;
  }

  toIdentify() {
    const distinctId = get_distinct_id();

    return distinctId;
  }
}
