import useSWR from 'swr';

import { getCourse } from '@/services/api/courses';

export const useLoadCourse = (courseId?: number) => {
  return useSWR(courseId ? { key: `getCourse`, courseId } : false, ({ key, ...o }) => getCourse(o), {
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
