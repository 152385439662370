import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { LegacyBadge } from '@chew/common/domains';

interface Props {
  badges: LegacyBadge[];
  className?: string;
}

const getBadgeItemSvg = (content?: string) => (
  <svg
    viewBox="0 0 56 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="h-13 w-16 drop-shadow-md transition-all"
  >
    <path
      d="M24.9231 6.77646C26.8271 5.67718 29.1729 5.67718 31.0769 6.77646L46.5737 15.7235C48.4777 16.8228 49.6506 18.8544 49.6506 21.0529V38.9471C49.6506 41.1456 48.4777 43.1772 46.5737 44.2765L31.0769 53.2235C29.1729 54.3228 26.8271 54.3228 24.9231 53.2235L9.42629 44.2765C7.52228 43.1772 6.34937 41.1456 6.34937 38.9471V21.0529C6.34937 18.8544 7.52228 16.8228 9.42629 15.7235L24.9231 6.77646Z"
      fill="white"
    />
    <path
      d="M24.9231 8.69956C26.8271 7.60028 29.1729 7.60028 31.0769 8.69956L44.9083 16.6851C46.8123 17.7844 47.9852 19.8159 47.9852 22.0145V37.9856C47.9852 40.1841 46.8123 42.2157 44.9083 43.3149L31.0769 51.3005C29.1729 52.3998 26.8271 52.3998 24.9231 51.3005L11.0917 43.3149C9.18774 42.2157 8.01482 40.1841 8.01482 37.9856V22.0145C8.01482 19.8159 9.18773 17.7844 11.0917 16.6851L24.9231 8.69956Z"
      fill={content ? '#02B478' : '#C6CCD7'}
    />
    <path
      d="M41.6539 21.5386L41.919 22.2349L42.6154 22.5001L41.919 22.7653L41.6539 23.4617L41.3887 22.7653L40.6923 22.5001L41.3887 22.2349L41.6539 21.5386Z"
      fill="white"
    />
    <path
      d="M18.5769 36.1538L18.8421 36.8502L19.5384 37.1153L18.8421 37.3805L18.5769 38.0769L18.3117 37.3805L17.6154 37.1153L18.3117 36.8502L18.5769 36.1538Z"
      fill="white"
    />
    <path
      d="M30.6923 9.36573L44.5237 17.3513C46.1897 18.3131 47.216 20.0907 47.216 22.0145V37.9856C47.216 39.9093 46.1897 41.6869 44.5237 42.6488L30.6923 50.6343C29.0263 51.5962 26.9737 51.5962 25.3077 50.6343L11.4764 42.6488C9.81035 41.6869 8.78405 39.9093 8.78405 37.9856V22.0145C8.78405 20.0907 9.81035 18.3131 11.4764 17.3513L25.3077 9.36573C26.9737 8.40386 29.0263 8.40386 30.6923 9.36573Z"
      stroke={content ? '#018F5F' : '#6C7C98'}
      strokeWidth="2"
    />

    {content && <image x="16" y="18" href={content} className="h-6 w-6 bg-cover" />}
  </svg>
);

export const Badges: React.FC<Props> = ({ badges, className }) => {
  const intl = useIntl();

  const getBadgeItem = (badge?: LegacyBadge) => (
    <div key={badge?.id} className="relative flex flex-col items-center group">
      {getBadgeItemSvg(badge?.imageSrc)}

      <div className="absolute bottom-9 flex-col items-center hidden mb-6 group-hover:flex w-48 transition-all">
        <span className="relative z-10 p-[10px] transition-all text-sm whitespace-no-wrap rounded-lg bg-white border border-solid border-purple-light shadow-lg">
          {badge ? badge.name : intl.formatMessage({ id: translations.pages.dashboard.stats.noBadge })}
        </span>
        <div className="w-3 h-3 -mt-2 rotate-45 border border-solid bg-white border-purple-light"></div>
      </div>
    </div>
  );

  return (
    <div {...{ className }}>
      <div className="font-extrabold">
        <FormattedMessage id={translations.pages.dashboard.stats.badges} />
      </div>

      <div className="flex flex-wrap relative mt-2">
        {badges.length === 0 && getBadgeItem()}

        {badges.map((badge) => getBadgeItem(badge))}
      </div>
    </div>
  );
};
