import { Profile } from '@chew/common/domains';
import { LessonWithMetadata } from '@/domains';

export const parsePosibleJSONString = <T>(value: unknown, fallback: T) => {
  if (typeof value === 'object') return value as unknown as T;
  if (typeof value !== 'string') return fallback;

  try {
    return JSON.parse(value) as T;
  } catch {
    return fallback;
  }
};

export const getContinueWatching = (me?: Profile) => {
  return parsePosibleJSONString<LessonWithMetadata[]>(me?.continueWatching, []);
};
