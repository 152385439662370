import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { LessonLockType } from '@chew/common/domains';
import { Button } from '@chew/common/components/Button';
import { Modal, useModal } from '@chew/common/components/Modal';

import { translations } from '@/locales';
import { analytics } from '@/services/analytics';

import { Stats } from '@/components/Stats';
import { Badges } from '@/components/Badges';
import { PagePadding } from '@/components/Page';
import {
  CollectionHeading,
  Collections,
  CollectionScrollableContent,
  CollectionScrolledList
} from '@/components/Lesson/LessonCollections';
import { Session } from '@/containers/SessionContainer';
import { LessonTile } from '@/components/Lesson/LessonTile';
import { PaywallModal } from '@/components/Lesson/PaywallModal';
import { UnavailableModal } from '@/components/Lesson/UnavailableModal';

import { useContinueWatchingLessons } from './hooks/useContinueWatchingLessons';
import { useCompletedLessons } from './hooks/useCompletedLessons';
import { useBadges } from './hooks/useBadges';

import emptyPan from './empty-pan.svg';

import placeholder from '@/assets/profile_placeholder.svg';

export const DashboardPage: React.FC = () => {
  const { me, loading } = Session.useContainer();

  const continueWatching = useContinueWatchingLessons();

  const { data: completedLessons = [], isValidating: lessonsLoading } = useCompletedLessons();

  const badges = useBadges();

  const paywallModal = useModal(PaywallModal);
  const unavailableModal = useModal(UnavailableModal);

  React.useEffect(() => {
    analytics.trackEvent('navigation', { type: 'Open dashboard' });
  }, []);

  return (
    <React.Fragment>
      {!loading && me && (
        <div className="py-8 space-y-8">
          <PagePadding className="gap-8">
            <Stats
              pointsEarned={me.pointsEarned}
              progressLevel={me.levelsCompleted + 1}
              progressPercent={me.progressPercent}
              lessonsWatched={me.lessonsCompleted}
              firstName={me.firstName}
              avatar={me.avatar ?? placeholder}
              badges={badges}
            />

            <Badges {...{ badges }} className="lg:hidden p-6 shadow rounded-3xl bg-white mt-6" />
          </PagePadding>

          <Collections>
            {!!continueWatching.length && (
              <React.Fragment>
                <PagePadding>
                  <CollectionHeading
                    title={<FormattedMessage id={translations.pages.dashboard.courses.continueWatching} />}
                  />
                </PagePadding>

                <CollectionScrollableContent>
                  <PagePadding>
                    <CollectionScrolledList>
                      {continueWatching.map((lesson) => (
                        <LessonTile
                          unlocked // TODO - Get level lock status instead
                          key={lesson.id}
                          {...{ lesson }}
                          onLocked={(lock) => {
                            switch (lock) {
                              case LessonLockType.Subscription:
                                return paywallModal.open(() => null);
                              case LessonLockType.Availability:
                                return unavailableModal.open(() => null);
                            }
                          }}
                        />
                      ))}
                    </CollectionScrolledList>
                  </PagePadding>
                </CollectionScrollableContent>
              </React.Fragment>
            )}

            {!!completedLessons.length && (
              <React.Fragment>
                <PagePadding>
                  <CollectionHeading
                    title={
                      <FormattedMessage
                        id={translations.pages.dashboard.courses.lessonsCompleted}
                        values={{ count: completedLessons.length }}
                      />
                    }
                  />
                </PagePadding>

                <CollectionScrollableContent>
                  <PagePadding>
                    <CollectionScrolledList>
                      {completedLessons.map((lesson) => (
                        <LessonTile
                          unlocked
                          key={lesson.id}
                          {...{ lesson }}
                          onLocked={(lock) => {
                            switch (lock) {
                              case LessonLockType.Subscription:
                                return paywallModal.open(() => null);
                              case LessonLockType.Availability:
                                return unavailableModal.open(() => null);
                            }
                          }}
                        />
                      ))}
                    </CollectionScrolledList>
                  </PagePadding>
                </CollectionScrollableContent>
              </React.Fragment>
            )}
          </Collections>

          {!loading && !lessonsLoading && !continueWatching.length && !completedLessons.length && (
            <PagePadding>
              <div className="flex flex-col items-center max-w-sm mx-auto text-center">
                <img src={emptyPan} role="presentation" className="w-full max-w-[200px]" />

                <h1 className="text-2xl font-bold">
                  <FormattedMessage id={translations.pages.dashboard.empty.title} />
                </h1>

                <p className="my-4 text-purple-medium">
                  <FormattedMessage id={translations.pages.dashboard.empty.message} values={{ name: me.firstName }} />
                </p>

                <Button is={Link} to="/courses" appearance="gradient-orange" className="px-16 h-16 font-medium">
                  <FormattedMessage id={translations.pages.dashboard.empty.callToAction} />
                </Button>
              </div>
            </PagePadding>
          )}

          <Modal {...paywallModal.props} />
          <Modal {...unavailableModal.props} />
        </div>
      )}
    </React.Fragment>
  );
};
