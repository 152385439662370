import React from 'react';

import { HiddenInput, Props as HiddenInputProps } from './HiddenInput';

export interface Props extends Omit<HiddenInputProps, 'value' | 'onChange' | 'multiple'> {
  // can be string because it might be an url already uploaded
  multiple?: boolean;
  value?: string | File | FileList | null;
  onChange: (files: FileList | null) => any;
}

export const FileUpload: React.FC<Props> = ({ className, children, multiple = false, onChange, ...rest }) => {
  return (
    <label htmlFor={rest.id} {...{ className }}>
      {children}

      <HiddenInput
        {...rest}
        type="file"
        {...{ multiple }}
        onChange={(event) => onChange(event.target.files)}
        value=""
      />
    </label>
  );
};
