import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Modal } from '@chew/common/components/Modal';

import { ProfileModal } from '@/components/ProfileModal';
import { Session } from '@/containers/SessionContainer';

import { HeaderLink } from './HeaderLink';
import { HeaderMenu } from './HeaderMenu';
import { UserProfile } from './UserProfile';

import logo from '@/assets/logo-light.svg';

export const Header: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { logout, me, loading } = Session.useContainer();

  return (
    <div className="fixed inset-x-0 top-0 flex w-full h-20 bg-orange-light justify-center items-center shadow z-50">
      <div className="flex justify-between items-center w-full px-5 md:px-20">
        <Link to="/dashboard" className="lg:w-52">
          <img src={logo} className="h-10" />
        </Link>

        <div className="hidden items-center gap-6 md:flex">
          <HeaderLink className="font-bold" to="/dashboard">
            <FormattedMessage id={translations.application.header.navigation.dashboard} />
          </HeaderLink>

          <HeaderLink className="font-bold" to="/courses">
            <FormattedMessage id={translations.application.header.navigation.courses} />
          </HeaderLink>
        </div>

        {!loading && (
          <React.Fragment>
            <HeaderMenu
              className="flex md:hidden"
              {...{ me }}
              onLogout={() => logout()}
              isModalOpen={(state) => setIsOpen(state)}
            />

            <UserProfile
              {...{ me }}
              onLogout={() => logout()}
              isModalOpen={(state) => setIsOpen(state)}
              className="md:block hidden"
            />

            <Modal
              //The modal forces all fields to be set, so we just have to check one
              type={!me?.age ? 'setup' : 'edit'}
              isOpen={!me?.age || isOpen}
              modal={ProfileModal}
              initialData={{ firstName: me?.firstName, lastName: me?.lastName, avatar: me?.avatar, age: me?.age }}
              onClose={() => setIsOpen(false)}
              onAction={() => null}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
