import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';

import { translations } from '@/locales';
import { analytics } from '@/services/analytics';
import { DigitalTask, DigitalTaskType } from '@/domains/digital-task';

import { Button } from '@chew/common/components/Button';
import { Modal, useModal } from '@chew/common/components/Modal';

import { DigitalTaskModal } from './DigitalTaskModal';

interface Props {
  levelId: number;
  digitalTask: DigitalTask<DigitalTaskType>;
  onCompleted(): void;
}

export const DigitalTaskNotice: React.FC<Props> = ({ levelId, digitalTask, onCompleted }) => {
  const modal = useModal(DigitalTaskModal);

  return (
    <div className="flex flex-col lg:flex-row lg:items-center rounded-2xl w-full bg-gradient-to-br from-[#6855E4] to-[#8A7BEA] p-6 lg:p-4 text-white mb-10">
      <img src={digitalTask.image.url} className="w-[80px] h-[72px] rounded-xl object-cover" />

      <div className="flex flex-col mt-2 lg:mt-0 lg:ml-4">
        <div className="flex items-center space-x-2">
          <span className="text-2xl">{digitalTask.title}</span>

          {digitalTask.completed && (
            <div className="px-3 text-sm text-center font-medium rounded-full bg-[#58C977]">
              <FormattedMessage id={translations.pages.courseList.done} />
            </div>
          )}
        </div>

        <div>{digitalTask.description}</div>
      </div>

      <Button
        appearance="none"
        onClick={() => modal.open(() => null)}
        className="space-x-4 py-4 px-8 mt-4 text-lg bg-white text-orange-light font-medium rounded-full lg:ml-auto lg:mt-0"
      >
        <span>
          <FormattedMessage id={translations.inputs.buttons.openDigitalTask} />
        </span>
        <ArrowNarrowRightIcon className="w-4 h-4" />
      </Button>

      <Modal
        {...modal.props}
        {...{ levelId, digitalTask, onCompleted }}
        onClose={() => {
          analytics.trackEvent('Tasks - Cancel task', {});
          modal.props.onClose();
        }}
      />
    </div>
  );
};
