import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Price, SubscriptionPlan as SubscriptionPlanDomain, SubscriptionPlanType } from '@/domains';
import { translations } from '@/locales';
import { roundToDecimals } from '@/util/math';

export interface Props {
  plan: SubscriptionPlanDomain;
  selected?: boolean;
  savings?: Price;
  className?: string;
  onClick: (plan: SubscriptionPlanDomain) => void;
}

interface GetPricePerMonth {
  type: SubscriptionPlanType;
  value: number;
}

const getPricePerMonth = ({ type, value }: GetPricePerMonth) => {
  switch (type) {
    case SubscriptionPlanType.Yearly:
      return roundToDecimals(value / 12, 2);
    default:
      return value;
  }
};

export const SubscriptionPlan: React.FC<Props> = ({ plan, savings, selected, onClick, className }) => {
  return (
    <div
      className={classnames(
        'w-full relative flex flex-wrap justify-between bg-[#FAFAFA] rounded-lg p-6 group font-bold text-xl hover:cursor-pointer hover:shadow-[0_0_0_1px_rgb(255,91,46)]',
        {
          'shadow-[0_0_0_1px_rgb(255,91,46)]': selected
        },
        className
      )}
      onClick={() => onClick(plan)}
    >
      <div className="flex justify-center">
        <div
          className={classnames(
            'relative w-[1.5rem] h-[1.5rem] border-[0.125rem] border-solid rounded-full mr-2 group-hover:border-orange-light',
            {
              'border-orange-light': selected,
              'border-[#CAC6CC]': !selected
            }
          )}
        >
          <div
            className={classnames(
              'absolute inset-y-1/2 inset-x-1/2 -translate-x-1/2 -translate-y-1/2 w-[1rem] h-[1rem] rounded-full bg-orange-light group-hover:block',
              {
                hidden: !selected
              }
            )}
          />
        </div>

        <span>
          <FormattedMessage id={translations.domains.subscriptionPlanType[plan.type]} />
        </span>
      </div>

      <span className="ml-8 md:ml-0">
        <FormattedMessage
          id={translations.miscellaneous.pricePerMonth}
          values={{
            value:
              plan.type === SubscriptionPlanType.Yearly
                ? plan.price.value
                : getPricePerMonth({ type: plan.type, value: plan.price.value }),
            currency: plan.price.currency,
            t: ((children: string) => (
              <span className="ml-1 uppercase text-sm">{children}</span>
            )) as unknown as React.ReactNode
          }}
        />
      </span>

      {savings && (
        <div className="absolute bg-orange-light right-6 p-[0.375rem] rounded-lg text-white text-[0.75rem] leading-3 top-0 -translate-y-1/2">
          <FormattedMessage
            id={translations.miscellaneous.youSave}
            values={{ value: savings.value, currency: savings.currency }}
          />
        </div>
      )}
    </div>
  );
};
