import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { CourseWithLessons, LessonLockType } from '@chew/common/domains';
import { Modal, useModal } from '@chew/common/components/Modal';

import { translations } from '@/locales';
import { analytics } from '@/services/analytics';
import { getLessonMetadata, getLessonLock } from '@/util/lesson';

import { useContinueWatchingLessons } from '@/pages/DashboardPage/hooks/useContinueWatchingLessons';

import { Session } from '@/containers/SessionContainer';
import { PaywallModal } from '@/components/Lesson/PaywallModal';
import { LessonDuration } from '@/components/Lesson/LessonDuration';
import { LessonThumbnail } from '@/components/Lesson/LessonThumbnail';
import { LessonPoints } from '@/components/Lesson/LessonPoints/LessonPoints';
import { UnavailableModal } from '@/components/Lesson/UnavailableModal';
import { LessonLockedOverlay, LessonProgress, LessonWatchedOverlay } from '@/components/Lesson/LessonOverlay';

interface Props extends React.HTMLProps<HTMLElement> {
  course: CourseWithLessons;
}

export const CourseOverviewSection: React.FC<Props> = ({ course, ...props }) => {
  const { me } = Session.useContainer();

  const continueWatchingLessons = useContinueWatchingLessons();

  const paywallModal = useModal(PaywallModal);
  const unavailableModal = useModal(UnavailableModal);

  const lessons = React.useMemo(
    () =>
      course.lessons.map((lesson) => {
        const lock = getLessonLock({ me, lesson, level: course.levelId });

        return {
          lesson,
          lock,
          to: `/courses/${course.id}/lessons/${lesson.id}`,
          progress: getLessonMetadata(continueWatchingLessons, lesson.id)?.progress,
          onClick: lock
            ? (event: React.MouseEvent) => {
                event.preventDefault();
                switch (lock) {
                  case LessonLockType.Subscription:
                    return paywallModal.open(() => null);
                  case LessonLockType.Availability:
                    return unavailableModal.open(() => null);
                }
              }
            : () => {
                analytics.trackEvent('Lesson - Select lesson', { lessonId: lesson.id });
              }
        };
      }),
    [me, continueWatchingLessons, paywallModal.open, unavailableModal.open]
  );

  return (
    <section {...props}>
      <h2 className="pb-6 text-2xl font-bold hidden lg:block">
        <FormattedMessage id={translations.pages.lessonDetail.sections.lessons.title} />
      </h2>

      <ul className="-my-2">
        {lessons.map(({ lesson, lock, to, progress, onClick }) => (
          <li key={lesson.id}>
            <NavLink {...{ to, onClick }} className="flex items-center py-2">
              {({ isActive }) => (
                <React.Fragment>
                  <LessonThumbnail src={lesson.image.url} square className="flex-shrink-0 w-16 mr-4 rounded-lg">
                    {lock ? (
                      <LessonLockedOverlay {...{ lock }} small />
                    ) : lesson.completed ? (
                      <LessonWatchedOverlay />
                    ) : (
                      !!progress && <LessonProgress {...{ progress }} />
                    )}
                  </LessonThumbnail>

                  <div>
                    <div className={classnames('font-bold', { 'text-orange-light': isActive })}>{lesson.title}</div>

                    <div className="flex space-x-4 text-purple-medium">
                      <LessonDuration duration={lesson.duration} />
                      <LessonPoints points={lesson.points} />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </NavLink>
          </li>
        ))}
      </ul>

      <Modal {...paywallModal.props} />
      <Modal {...unavailableModal.props} />
    </section>
  );
};
