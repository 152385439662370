import React from 'react';
import { useIntl } from 'react-intl';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { translations } from '@/locales';

import { FACEBOOK_APP_ID } from '@chew/common/config';

import { SocialAuthIntent } from '@/services/api';

import { Session } from '@/containers/SessionContainer';

import { SocialButton } from './SocialButton';

interface Props {
  intent: SocialAuthIntent;
  className?: string;
  onError(error: Error): void;
}

const splitFullName = (fullName?: string) => {
  if (!fullName) return;
  const [firstName, lastName] = fullName.split(' ');
  return { firstName, lastName };
};

export const FacebookButton = ({ intent, className, children, onError }: React.PropsWithChildren<Props>) => {
  const intl = useIntl();

  const { socialAuth } = Session.useContainer();

  const onSuccess = (response: ReactFacebookLoginInfo) => {
    socialAuth(intent, {
      oauth_client: 'facebook',
      oauth_client_id: response.id,
      oauth_client_picture: response?.picture?.data.url,
      email: response?.email,
      ...splitFullName(response?.name),
      fullName: response.name
    });
  };

  const onFailure = () => {
    onError(new Error(intl.formatMessage({ id: translations.errors.api.social_login })));
  };

  return (
    <FacebookLogin
      appId={FACEBOOK_APP_ID}
      isMobile={false}
      fields="name,email,picture"
      callback={onSuccess}
      onFailure={onFailure}
      render={(renderProps) => (
        <SocialButton {...{ className }} onClick={renderProps.onClick} provider="facebook">
          {children}
        </SocialButton>
      )}
    />
  );
};
