import useSWR from 'swr';

import { getCompletedLessons } from '@/services/api/courses';

export const useCompletedLessons = () => {
  return useSWR({ key: `getCompletedLessons` }, () => getCompletedLessons(), {
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
