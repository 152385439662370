import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PencilIcon } from '@heroicons/react/solid';

import { translations } from '@/locales';

import { Button } from '@chew/common/components/Button';

export interface Props {
  className?: string;
  onClick: () => void;
}

export const EditButton: React.FC<Props> = ({ className, ...rest }) => {
  return (
    <div {...{ className }}>
      <div className="md:hidden">
        <button className="self-start" {...rest}>
          <PencilIcon className="w-4 h-4 fill-orange-light" />
        </button>
      </div>

      <div className="hidden md:block">
        <Button appearance="orange-hollow" className="text-orange-light px-6 group font-bold" {...rest}>
          <FormattedMessage id={translations.inputs.buttons.edit} />

          <PencilIcon className="ml-1 w-4 h-4 fill-orange-light group-hover:fill-white transition-colors" />
        </Button>
      </div>
    </div>
  );
};
