import { Image, Video } from './asset';
import { LessonBadge } from './me';

export interface Lesson {
  id: number;
  courseId: number;
  title: string;
  description?: string;
  paid: boolean;
  duration: string;
  image: Image;
  points: number;
  watched: boolean;
  completed: boolean;
  available: boolean;
  badge: LessonBadge;
}

export interface RecipeStep {
  image: Image;
  description: string;
}

export interface Recipe {
  steps: RecipeStep[];
}

export interface Ingredient {
  name: string;
  amount: string;
}

export interface LessonDetail extends Lesson {
  recipe: Recipe;
  ingredients: Ingredient[];
  video?: Video;
  quiz: Quiz;
}

export interface Quiz {
  id: number;
  maxPoints: number;
  partialPoints: number;
  questions: QuizQuestion[];
}

export interface QuizQuestion {
  id: number;
  imageSrc: string;
  videoSrc: string;
  question: string;
  rightAnswerId: number;
  answers: QuizQuestionAnswer[];
}

export interface QuizQuestionAnswer {
  id: number;
  text: string;
}

export enum LessonLockType {
  Subscription = 'subscription',
  Availability = 'availability'
}
