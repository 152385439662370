import React from 'react';
import * as yup from 'yup';
import { RadioGroup } from '@headlessui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { updateProfile } from '@/services/api';
import { analytics } from '@/services/analytics';
import { firstNameSchema, lastNameSchema, photoSchema } from '@/schemas';

import { Button } from '@chew/common/components/Button';
import { InstanceProps } from '@chew/common/components/Modal';
import { Form, FormField } from '@chew/common/components/Form';
import { TextInputWithValidation } from '@chew/common/components/Input/TextInput';

import PictureUpload from '@/components/PictureUpload';
import { Session } from '@/containers/SessionContainer';

interface Props extends InstanceProps<[]> {
  type: 'setup' | 'edit';
  initialData?: {
    firstName?: string;
    lastName?: string;
    avatar?: string;
    age?: string;
  };
}

const schema = yup.object({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  avatar: photoSchema.label(translations.inputs.avatar).optional()
});

const ageRanges = ['6-8', '9-11', '12-14'];

const title = translations.modals.profileModal.title;

export const ProfileModal: React.FC<Props> = ({ type, initialData, onClose }) => {
  const intl = useIntl();
  const { loadMe } = Session.useContainer();

  const [ageRange, setAgeRange] = React.useState(
    initialData?.age ? ageRanges.find((item) => item === initialData?.age) : ageRanges[0]
  );

  React.useEffect(() => {
    analytics.trackEvent('navigation', { type: 'Open edit profile' });
  }, []);

  return (
    <Form
      schema={schema}
      initialValues={{ ...initialData }}
      onSubmit={(values) =>
        updateProfile({
          ...values,
          fullName: `${values.firstName} ${values.lastName}`,
          age: ageRange as string
        }).then(() => loadMe().then(onClose))
      }
      className="flex flex-col max-w-[300px] p-8 xs:max-w-md sm:max-w-lg"
    >
      {({ submitting }) => (
        <div className="flex flex-col">
          <div className="text-3xl font-extrabold text-center mb-6">
            <FormattedMessage id={type === 'edit' ? title.edit : title.complete} />
          </div>

          <div className="mb-2 sm:mb-6">
            <FormField as={PictureUpload} id="avatar" name="avatar" readOnly={submitting} />
          </div>

          <div className="flex flex-col xs:flex-row gap-2 w-full">
            <FormField
              as={TextInputWithValidation}
              id="firstName"
              name="firstName"
              type="text"
              placeholder={intl.formatMessage({ id: translations.inputs.firstName.placeholder })}
              label={intl.formatMessage({ id: translations.inputs.firstName.label })}
              readOnly={submitting}
            />

            <FormField
              as={TextInputWithValidation}
              id="lastName"
              name="lastName"
              type="text"
              placeholder={intl.formatMessage({ id: translations.inputs.lastName.placeholder })}
              label={intl.formatMessage({ id: translations.inputs.lastName.label })}
              readOnly={submitting}
            />
          </div>

          <div className="mt-6 xs:mt-12 text-lg font-extrabold">
            <FormattedMessage id={translations.modals.profileModal.selectAgeRange} />
          </div>

          <RadioGroup value={ageRange} onChange={setAgeRange}>
            <div className="flex gap-2 items-center flex-wrap mt-2">
              {ageRanges.map((range, index) => (
                <RadioGroup.Option
                  key={index}
                  value={range}
                  as={Button}
                  type="button"
                  appearance="orange-hollow"
                  className={({ checked }) =>
                    `${checked ? 'bg-[#FFEFEB] bg-opacity-75 text-orange-light' : 'bg-white border-purple-medium'}
                    relative flex cursor-pointer text-lg px-4 py-2 shadow-md focus:outline-none hover:border-orange-light hover:text-white`
                  }
                >
                  <FormattedMessage id={translations.modals.profileModal.ageRange} values={{ ageRange: range }} />
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>

          <Button type="submit" disabled={submitting} appearance="gradient-orange" className="mt-10 h-16">
            <FormattedMessage id={translations.inputs.buttons.saveProfile} />
          </Button>
        </div>
      )}
    </Form>
  );
};
