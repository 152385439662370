import React from 'react';
import classnames from 'classnames';

interface Props {
  text: string;
  isSelected: boolean;
  onClick(): void;
}

export const AnswerOptionButton: React.FC<Props> = ({ text, isSelected, onClick }) => (
  <div
    className={classnames(
      'flex w-full  border border-solid gap-3 items-center py-4 px-6 text-xl font-bold rounded-2xl cursor-pointer select-none',
      isSelected ? 'bg-[#FFF7F5] border-orange-light' : 'bg-[#F3F3F3]'
    )}
    onClick={onClick}
  >
    <div
      className={classnames(
        'flex min-h-[24px] min-w-[24px] rounded-full border border-solid items-center justify-center',
        isSelected ? 'border-orange-light' : 'border-purple-light'
      )}
    >
      {isSelected && <div className="h-4 w-4 rounded-full bg-orange-light" />}
    </div>

    <div className="">{text}</div>
  </div>
);
