import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { LessonDetail } from '@chew/common/domains';

import { LessonDuration } from '@/components/Lesson/LessonDuration';
import { LessonPoints } from '@/components/Lesson/LessonPoints/LessonPoints';

import { Button } from '@chew/common/components/Button';

interface Props extends React.HTMLProps<HTMLElement> {
  lesson: LessonDetail;
  onStartQuiz(): void;
}

export const LessonSummarySection: React.FC<Props> = ({ lesson, children, onStartQuiz, ...props }) => (
  <section {...props}>
    {children}

    <div className="flex py-3 justify-between w-full text-purple-medium lg:py-6">
      <div className="flex space-x-4">
        <LessonDuration duration={lesson.duration} />
        <LessonPoints points={lesson.points} />
      </div>

      {!lesson.completed && (
        <Button appearance="gradient-orange" className="px-6 min-w-[150px] hidden lg:flex" onClick={onStartQuiz}>
          <FormattedMessage id={translations.modals.quizNoticeModal.button} />
        </Button>
      )}
    </div>

    <div className="text-purple-medium mb-10">{lesson.description}</div>

    {!lesson.completed && (
      <div className="fixed right-4 bottom-4 z-10">
        <Button appearance="gradient-orange" className="px-6 min-w-[150px] h-16 lg:hidden" onClick={onStartQuiz}>
          <FormattedMessage id={translations.modals.quizNoticeModal.button} />
        </Button>
      </div>
    )}
  </section>
);
