import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import starImage from './star.png';
import { translations } from '@/locales';

interface Props {
  points: number;
  className?: string;
}

export const LessonPoints: React.FC<Props> = ({ points, className }) => (
  <p className={classnames('flex items-center font-inter tracking-wide', className)}>
    <img src={starImage} role="presentation" className="w-4 h-4 -mt-[2px] mr-2" />
    <FormattedMessage id={translations.domains.lesson.points} values={{ points }} />
  </p>
);
