import React from 'react';
import classnames from 'classnames';
import { Menu } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';
import { ChevronDownIcon } from '@heroicons/react/solid';

import { Profile } from '@chew/common/domains';

import { translations } from '@/locales';
import { analytics } from '@/services/analytics';

import { MenuButton } from './MenuButton';

import cog from '@/assets/cog.png';
import door from '@/assets/door.png';
import placeholder from '@/assets/profile_placeholder.svg';

interface Props {
  className?: string;
  me?: Profile;
  isModalOpen(state: boolean): void;
  onLogout(): void;
}

export const UserProfile: React.FC<Props> = ({ className, me, isModalOpen, onLogout }) => {
  return (
    <Menu as="div" className={classnames('relative text-white cursor-pointer', className)}>
      <Menu.Button as="div" className="flex items-center">
        <div className="mr-4">
          <div className="font-bold">{me?.fullName}</div>

          <div className="text-sm">
            <FormattedMessage
              id={translations.application.header.userProfile.stats}
              values={{ level: (me?.levelsCompleted ?? 0) + 1, points: me?.pointsEarned }}
            />
          </div>
        </div>

        <img src={me?.avatar ?? placeholder} className="h-11 w-11 bg-red-900 rounded-2xl object-cover" />

        <ChevronDownIcon className="h-6" />
      </Menu.Button>

      <Menu.Items className="absolute top-11 right-0 min-w-[140px] bg-white rounded-2xl shadow-xl overflow-hidden flex flex-col focus:outline-none">
        <MenuButton
          icon={cog}
          onClick={() => {
            analytics.trackEvent('navigation', { type: 'Open edit profile' });
            isModalOpen(true);
          }}
        >
          <FormattedMessage id={translations.application.header.profileMenu.settings} />
        </MenuButton>

        <MenuButton icon={door} onClick={() => onLogout()}>
          <FormattedMessage id={translations.application.header.profileMenu.logOut} />
        </MenuButton>
      </Menu.Items>
    </Menu>
  );
};
