import React from 'react';
import { NavLink } from 'react-router-dom';

import classNames from 'classnames';

interface Props {
  to: string;
  onClick?(): void;
  className?: string;
}

export const HeaderLink: React.FC<React.PropsWithChildren<Props>> = ({ to, onClick, className, children }) => (
  <NavLink to={to} {...{ onClick }}>
    {({ isActive }) => (
      <div
        className={classNames(
          'text-header p-3 rounded-lg text-white transition-transform',
          isActive && 'bg-orange-dark ml-3 md:ml-0',
          className
        )}
      >
        {children}
      </div>
    )}
  </NavLink>
);
