import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Profile } from '@chew/common/domains';
import { Button } from '@chew/common/components/Button';

import { useOpen } from '@chew/common/hooks/useOpen';
import { HeaderHamburger } from '@chew/common/components/HeaderHamburger';

import { HeaderLink } from './HeaderLink';

import cog from '@/assets/cog.png';
import door from '@/assets/door.png';
import placeholder from '@/assets/profile_placeholder.svg';

interface Props {
  className?: string;
  me?: Profile;
  isModalOpen(state: boolean): void;
  onLogout(): void;
}
export const HeaderMenu: React.FC<Props> = ({ className, me, isModalOpen, onLogout }) => {
  const header = useOpen(false);

  return (
    <React.Fragment>
      <HeaderHamburger appearance="app" onClick={header.toggle} isOpen={header.isOpen} />

      {header.isOpen && (
        <div
          className={classnames(
            'fixed inset-0 top-20 bg-orange-light px-5 text-white z-10 transition-all flex-col overflow-scroll',
            className
          )}
        >
          <img
            src={me?.avatar ?? placeholder}
            className="h-20 w-20 bg-orange-dark rounded-2xl object-cover mb-3 mt-6"
          />

          <div className="text-2xl font-bold">{me?.fullName}</div>

          <div className="text-base">
            <FormattedMessage
              id={translations.application.header.userProfile.stats}
              values={{ level: (me?.levelsCompleted ?? 0) + 1, points: me?.pointsEarned }}
            />
          </div>

          <div className="h-[2px] w-full bg-[#FF8361] my-4" />

          <HeaderLink className="w-full text-2xl font-bold" to="/dashboard" onClick={() => header.close()}>
            <FormattedMessage id={translations.application.header.navigation.dashboard} />
          </HeaderLink>

          <HeaderLink className="w-full text-2xl font-bold" to="/courses" onClick={() => header.close()}>
            <FormattedMessage id={translations.application.header.navigation.courses} />
          </HeaderLink>

          <div className="mt-auto flex flex-col items-start mb-20 gap-2">
            <Button
              appearance="none"
              className="flex rounded-lg items-center text-2xl gap-2 hover:bg-orange-dark cursor-pointer h-10 px-3 transition-colors"
              onClick={() => {
                header.close();
                isModalOpen(true);
              }}
            >
              <img src={cog} className="h-4 object-cover" />
              <FormattedMessage id={translations.application.header.profileMenu.settings} />
            </Button>

            <Button
              className="flex rounded-lg items-center text-2xl gap-2 hover:bg-orange-dark cursor-pointer h-10 px-3 transition-colors"
              onClick={() => onLogout()}
            >
              <img src={door} className="h-4 object-cover" />

              <FormattedMessage id={translations.application.header.profileMenu.logOut} />
            </Button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
