import mixpanel from 'mixpanel-browser';

import { Analytics } from '@chew/common/services/analytics/types';
import { UserTrackerCallback } from '@chew/common/services/analytics/user-tracker';

import { EventProperties, EventType } from '../event-properties';
import { UserProperties } from '../user-properties';

import { MixpanelUserTracker } from './mixpanel-user-tracker';

interface MixpanelAnalyticsOptions {
  token: string;
}

export class MixpanelAnalytics implements Analytics<EventProperties, UserProperties> {
  constructor({ token }: MixpanelAnalyticsOptions) {
    mixpanel.init(token);
  }

  getCrossDomainParameters(): Record<string, string | undefined> {
    return {};
  }

  trackEvent<E extends EventType>(type: E, event: EventProperties[E], userProperties?: Partial<UserProperties>): void {
    mixpanel.track(type, { event_type: type, event_properties: event, user_properties: userProperties });
  }

  setCurrentUser(userId?: string): void {
    return mixpanel.identify(userId as string);
  }

  trackUser(callback: UserTrackerCallback<UserProperties>) {
    const tracker = new MixpanelUserTracker();
    callback(tracker);
  }
}
