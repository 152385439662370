import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Button } from '@chew/common/components/Button';

interface Props {
  grade: number;
  onClick(): void;
}

const text = translations.modals.quizFailedModal;

export const QuizFailedNotice: React.FC<Props> = ({ grade, onClick }) => {
  return (
    <div className="flex flex-col w-full items-center py-6">
      <div className="text-orange-light font-extrabold uppercase mb-4">
        <FormattedMessage id={text.title} />
      </div>

      <div className="text-2xl font-extrabold mb-4 px-6 text-center">
        <FormattedMessage id={text.subtitle} />
      </div>

      <div className="text-3xl font-extrabold">
        <FormattedMessage id={text.score} values={{ grade: Math.floor(grade) }} />
      </div>

      <Button type="button" {...{ onClick }} appearance="gradient-orange" className="mt-5 px-8 h-16 min-w-[200px]">
        <FormattedMessage id={text.continue} />
      </Button>
    </div>
  );
};
