import useSWR from 'swr';

import { getLevels } from '@/services/api/levels';

export const useLoadLevels = () => {
  return useSWR({ key: `getLevels` }, () => getLevels(), {
    revalidateIfStale: false,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
};
