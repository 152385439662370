import React from 'react';
import classnames from 'classnames';

import { LessonDetail } from '@chew/common/domains';

interface Props extends React.HTMLProps<HTMLElement> {
  lesson: LessonDetail;
}

const recipeImageBorders = ['rounded-tr-[80px]', 'rounded-bl-[80px]', 'rounded-br-[80px]', 'rounded-tl-[80px]'];

export const RecipeSection: React.FC<Props> = ({ lesson, ...props }) => (
  <section {...props}>
    <ul className="space-y-12 lg:space-y-6">
      {lesson.recipe.steps.map((step, index) => (
        <li key={index} className="flex flex-nowrap justify-end group even:flex-row-reverse">
          <div className="flex-1 lg:p-8 lg:rounded-2xl lg:bg-[#F7F3F0] lg:max-w-[640px]">
            <div className="group-odd:pr-4 group-even:pl-4 lg:group-odd:pr-10 lg:group-even:pl-10">
              <div className="relative inline-block text-2xl text-[#764A04] font-bold">
                <span className="absolute center">{index + 1}</span>

                <svg viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-12">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.1778 0.00525954C35.6289 -0.259966 46.242 9.53995 49.1261 21.6553C51.49 31.5853 42.4562 39.2909 33.8287 44.7461C27.1721 48.9551 19.0308 49.1796 12.4582 44.8407C4.70578 39.7229 -1.73762 31.7275 0.422477 22.6928C3.17609 11.1756 11.3387 0.257448 23.1778 0.00525954Z"
                    fill="#FACA7E"
                  />
                </svg>
              </div>

              <div className="pt-4 text-purple-medium">{step.description}</div>
            </div>
          </div>

          <div className="flex-shrink-0 w-1/3 max-w-[160px] lg:my-4 lg:group-odd:-ml-10 lg:group-even:-mr-10">
            <img
              src={step.image.url}
              className={classnames(
                'aspect-square object-cover rounded-3xl h-full w-full',
                recipeImageBorders[index % recipeImageBorders.length]
              )}
            />
          </div>
        </li>
      ))}
    </ul>
  </section>
);
