import React, { PropsWithChildren } from 'react';

import logo from '@/assets/logo-orange.svg';
import top from '@/assets/top-line.svg';
import bottom from '@/assets/bottom-line.svg';

export const AuthBasePage: React.FC<PropsWithChildren> = ({ children }) => (
  <React.Fragment>
    <div className="fixed inset-0 bg-white xs:bg-doodles xs:bg-[#F6ECE1] bg-cover bg-no-repeat" />

    <img src={top} className="absolute xs:hidden top-0 left-0" />
    <img src={bottom} className="absolute xs:hidden bottom-0 right-0" />

    <div className="flex flex-col items-center justify-center w-full mb-6 xs:px-6 z-10">
      <img src={logo} className="h-20 mt-9 xs:mb-4 z-10" />

      <div className="flex flex-col max-w-lg p-8 w-full xs:drop-shadow-beige xs:border border-solid border-gray-light xs:bg-white text-purple-dark relative rounded-3xl">
        {children}
      </div>
    </div>
  </React.Fragment>
);
