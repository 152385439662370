import React from 'react';
import classnames from 'classnames';

export type Color = 'orange-light';

export interface Props {
  color: Color;
  className: string;
  children: React.ReactElement<{ className: string }>;
}

const style: Record<Color, { root: string; child: string }> = {
  'orange-light': {
    root: 'after:bg-orange-light',
    child: 'text-orange-light'
  }
};

export const RoundedIcon: React.FC<Props> = ({ color, className, children }) => {
  return (
    <div
      className={classnames(
        'relative after:rounded-full after:inline-block after:opacity-30 after:w-full after:h-full mr-4',
        style[color].root,
        className
      )}
    >
      {React.cloneElement(React.Children.only(children), {
        ...children.props,
        className: classnames(
          'absolute inset-x-1/2 inset-y-1/2 -translate-x-1/2 -translate-y-1/2',
          style[color].child,
          children.props.className
        )
      })}
    </div>
  );
};
