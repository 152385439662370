import React from 'react';
import classnames from 'classnames';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean;
}

export const HiddenInput: React.FC<Props> = ({ invalid, ...props }) => {
  return (
    <input {...props} className={classnames('block opacity-0 w-0 h-0 m-0 pointer-events-none', props.className)} />
  );
};
