import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';

export interface ValidationError {
  id: string;
  values: any;
}

export interface Props extends ValidationError {
  visible: boolean;
  className?: string;
}

const valueMapper: Record<string, Function> = {
  // 'validation.basic.date.min': (values) => ({ ...values, value: 'value', min: 'min' }),
  // 'validation.basic.date.max': (values) => ({ ...values, value: 'value', max: 'max' })
};

export const ValidationMessage: React.FC<Props> = ({ visible, id, values, className, ...rest }) => {
  const intl = useIntl();

  const formattedValues = values
    ? { ...values, path: intl.formatMessage({ id: values.label || id }).toLowerCase() }
    : {};

  return (
    <div
      className={classnames(
        'mt-1 text-red-700 text-left transition-opacity',
        { 'visible opacity-100': visible },
        className
      )}
      role="alert"
      {...rest}
    >
      {!!id && (
        <FormattedMessage id={id} values={valueMapper[id] ? valueMapper[id](formattedValues) : formattedValues} />
      )}
    </div>
  );
};
