import React from 'react';
import { useList } from 'react-use';

import { completeQuiz } from '@/services/api/quiz';

import { LessonBadge, QuizQuestion } from '@chew/common/domains';

export const useQuiz = (
  badge: LessonBadge,
  questions: QuizQuestion[],
  quizId: number,
  lessonId: string,
  courseId: string,
  onCompletion: () => void
) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const [quizAnswers, { updateAt, clear }] = useList<{ answerIds: number[] }>([]);
  const [quizCompleted, setQuizCompleted] = React.useState(false);
  const [quizPoints, setQuizPoints] = React.useState(0);

  const nextQuestion = () => {
    if (currentQuestionIndex > questions.length - 2) return;

    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const clearQuiz = () => {
    clear();
    setCurrentQuestionIndex(0);
    setQuizCompleted(false);
  };

  const registerAnswer = (answerIds: number[]) => {
    updateAt(currentQuestionIndex, { answerIds });

    nextQuestion();
  };

  const isCorrectAnswer = (userAnswers: number[], validAnswer: number) =>
    userAnswers.some((item) => item === validAnswer);

  const correctAnswers = quizAnswers
    .map((answer, index) => isCorrectAnswer(answer.answerIds, questions[index].rightAnswerId))
    .reduce((total, current) => total + +current, 0);

  const grade = (correctAnswers * 100) / questions.length;

  React.useEffect(() => {
    if (quizCompleted) return;
    if (quizAnswers.length !== questions.length) return;

    const promise =
      grade > 80 ? completeQuiz({ lessonId, quizId: quizId.toString(), courseId, grade, badge }) : Promise.resolve();

    promise.then((response) => {
      setQuizPoints(response!);

      setQuizCompleted(true);
      onCompletion();
    });
  }, [quizAnswers]);

  return {
    currentQuestion: questions[currentQuestionIndex],
    currentQuestionIndex,
    registerAnswer,
    nextQuestion,
    grade,
    clearQuiz,
    quizCompleted,
    quizPoints
  };
};
