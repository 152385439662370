/**
 * LEGACY: Renders Klarna Checkout HTML to DOM
 * @param htmlSnippet HTML code snippet to be rendered
 * @param containerId id of the element to render to
 */
export const renderSnippet = function (htmlSnippet: string, containerId: string) {
  const snippetContainer = document.getElementById(containerId);

  if (!snippetContainer) return;

  snippetContainer.innerHTML = htmlSnippet;
  const scriptsTags = snippetContainer.getElementsByTagName('script');

  // This is necessary otherwise the scripts tags are not going to be evaluated
  for (let i = 0; i < scriptsTags.length; i++) {
    const parentNode = scriptsTags[i].parentNode;

    if (!parentNode) return;
    const newScriptTag = document.createElement('script');
    newScriptTag.type = 'text/javascript';
    newScriptTag.text = scriptsTags[i].text;
    parentNode.removeChild(scriptsTags[i]);
    parentNode.appendChild(newScriptTag);
  }
};
