import React from 'react';
import classnames from 'classnames';
import { useMedia } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { CourseWithLessons, LessonDetail } from '@chew/common/domains';

import { translations } from '@/locales';
import { getLessonLock } from '@/util/lesson';
import { analytics } from '@/services/analytics';

import { Session } from '@/containers/SessionContainer';

import { useModal } from '@chew/common/components/Modal';

import { PagePadding } from '@/components/Page';
import { QuizNoticeModal, QuizConfettiModal } from '@/components/Quiz';

import {
  NavigationSection,
  LessonVideoSection,
  LessonSummarySection,
  RecipeSection,
  CourseOverviewSection,
  IngredientSection
} from './sections';

import { useLoadCourse } from '../hooks/useLoadCourse';
import { useLoadLesson } from '../hooks/useLoadLesson';

interface LessonDetailProps {
  course?: CourseWithLessons;
  lesson?: LessonDetail;
  video: React.ReactNode;
  onStartQuiz(): void;
}

export const MobileLessonDetail: React.FC<LessonDetailProps> = ({ course, lesson, video, onStartQuiz }) => {
  const { hash } = useLocation();

  return (
    <div className="flex-1 bg-white">
      {video}

      <PagePadding>
        <NavigationSection className="sticky top-20 py-2 mt-4 border-b-2 border-gray-light bg-white z-10" />

        <div className="py-8">
          {!!lesson && !hash && (
            <LessonSummarySection {...{ lesson, onStartQuiz }} className="relative">
              <h1 className={classnames('text-3xl font-bold')}>{lesson.title}</h1>
            </LessonSummarySection>
          )}

          {!!course && hash === '#lessons' && <CourseOverviewSection {...{ course }} />}

          {!!lesson && hash === '#recipe' && <RecipeSection {...{ lesson }} />}

          {!!lesson && hash === '#ingredients' && <IngredientSection {...{ lesson }} />}
        </div>
      </PagePadding>
    </div>
  );
};

export const DesktopLessonDetail: React.FC<LessonDetailProps> = ({ course, lesson, video, onStartQuiz }) => (
  <div className="flex flex-1 items-start bg-regular bg-[#F6ECE1] relative">
    <div className="flex flex-col flex-1 pl-20 pr-10 pt-10 bg-white">
      {!!lesson && <h1 className={classnames('text-3xl font-bold pb-4')}>{lesson.title}</h1>}

      {video}

      {!!lesson && <LessonSummarySection {...{ lesson, onStartQuiz }} />}

      {!!lesson && <RecipeSection {...{ lesson }} className="py-8 pt-20" />}
    </div>

    <div className="sticky top-20 flex-1 pl-10 pr-20 py-8 space-y-8 max-w-[33%] bg-right-top">
      {!!course && <CourseOverviewSection {...{ course }} />}

      {!!lesson && <IngredientSection {...{ lesson }} />}
    </div>
  </div>
);

export const LessonDetailPage: React.FC = () => {
  const { courseId, lessonId } = useParams<{ courseId: string; lessonId: string }>();
  const location = useLocation();
  const isLarge = useMedia('(min-width: 1024px)');
  const { me } = Session.useContainer();

  React.useEffect(() => {
    if (!courseId && !lessonId) return;

    analytics.trackEvent('Lesson - View course detaill', {
      courseId: parseInt(courseId as string),
      lessonId: parseInt(lessonId as string)
    });
  }, [courseId, lessonId]);

  const { data: course, error, mutate: reloadCourse } = useLoadCourse(+courseId!);
  const { data: lesson, mutate: reloadLesson } = useLoadLesson(+lessonId!);

  const onQuizCompleted = React.useCallback(async () => {
    await Promise.all([reloadCourse(), reloadLesson()]);
  }, [reloadCourse, reloadLesson]);

  const lock = getLessonLock({ me, lesson, level: course?.levelId });
  const state = location.state as { autoPlay: boolean; progress?: number } | undefined;
  const autoPlay = !!state?.autoPlay;

  const quizNoticeModal = useModal(QuizNoticeModal);
  const quizModal = useModal(QuizConfettiModal);

  if (error) return <FormattedMessage id={translations.application.error.title} />;

  if (lock) return <Navigate to="/courses" replace />;

  const onStartQuiz = () => quizModal.open(() => null);

  const onWatched = () => {
    if (lesson?.watched) return;

    if (quizModal.props.isOpen) return;

    return quizNoticeModal.open(onStartQuiz);
  };

  const video = (
    <LessonVideoSection {...{ lesson, autoPlay, onWatched, lock }} className="overflow-hidden lg:rounded-2xl" />
  );

  return (
    <div className="flex-1 bg-white">
      {isLarge ? (
        <DesktopLessonDetail {...{ course, lesson, video, onStartQuiz }} />
      ) : (
        <MobileLessonDetail {...{ course, lesson, video, onStartQuiz }} />
      )}

      {!!lesson && (
        <React.Fragment>
          <QuizNoticeModal {...quizNoticeModal.props} />

          <QuizConfettiModal
            {...quizModal.props}
            quiz={lesson?.quiz}
            onCompleted={onQuizCompleted}
            badge={lesson.badge}
          />
        </React.Fragment>
      )}
    </div>
  );
};
