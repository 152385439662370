import React from 'react';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { analytics } from '@/services/analytics';
import { register, SocialAuthIntent } from '@/services/api';
import { metaPixelAnalytics } from '@/services/analytics/meta-pixel';

import { emailSchema } from '@/schemas';

import { Button } from '@chew/common/components/Button';
import { Form, FormField } from '@chew/common/components/Form';
import { TextInputWithValidation } from '@chew/common/components/Input/TextInput';

import { GoogleButton, FacebookButton } from '@/components/Social';

//TODO move these to the config file so they are consistent with the environment
const PRIVACY_POLICY_URL = 'https://landing-dev-chewas.vercel.app/privacy';
const TERMS_URL = 'https://landing-dev-chewas.vercel.app/terms';

const schema = yup.object({
  email: emailSchema
});

export const SignUpPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [hasError, setHasError] = React.useState(false);

  const onSubmit = (email: string) => {
    register(email).then((response) => {
      if (response.data.message === 'Email is already exists.') setHasError(true);
      else {
        analytics.trackEvent('Auth - Sign up', { type: 'email' });
        metaPixelAnalytics.trackEvent('CompleteRegistration', { type: 'email' });
        navigate('/sign-up-confirmation', { state: email });
      }
    });
  };

  return (
    <Form schema={schema} onSubmit={({ email }) => onSubmit(email)}>
      {({ submitting }) => (
        <div className="flex flex-col">
          <div className="text-3xl font-extrabold text-center mb-6">
            <FormattedMessage id={translations.pages.signUp.title} />
          </div>

          <GoogleButton intent={SocialAuthIntent.Registration} onError={() => null} className="mb-2">
            <FormattedMessage id={translations.inputs.buttons.google.signUp} />
          </GoogleButton>

          <FacebookButton intent={SocialAuthIntent.Registration} onError={() => null}>
            <FormattedMessage id={translations.inputs.buttons.facebook.signUp} />
          </FacebookButton>

          <div className="flex items-center my-4">
            <div className="h-[1px] bg-gray-light w-full" />
            <span className="text-purple-medium mx-2">or</span>
            <div className="h-[1px] bg-gray-light w-full" />
          </div>

          <div className="text-header font-extrabold mb-1">
            <FormattedMessage id={translations.inputs.email.label} />
          </div>

          <FormField
            as={TextInputWithValidation}
            id="email"
            name="email"
            type="email"
            placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
          />

          <div className="text-sm font-bold text-purple-medium mt-2">
            <FormattedMessage id={translations.pages.signUp.passwordNotice} />
          </div>

          {hasError && (
            <div className="text-sm font-bold text-orange-light mt-2">
              <FormattedMessage id={translations.errors.api.user_email_duplicate} />
            </div>
          )}

          <Button appearance="gradient-orange" className="mt-9 h-16" disabled={submitting}>
            <FormattedMessage id={translations.inputs.buttons.signUp} />
          </Button>

          <div className="text-xs text-purple-medium text-center mt-4">
            <FormattedMessage
              id={translations.pages.signUp.termsNotice}
              values={{
                br: <br />,
                privacy: ((text: string) => (
                  <a
                    href={PRIVACY_POLICY_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-orange-light underline"
                  >
                    {text}
                  </a>
                )) as unknown as React.ReactNode,
                terms: ((text: string) => (
                  <a href={TERMS_URL} target="_blank" rel="noopener noreferrer" className="text-orange-light underline">
                    {text}
                  </a>
                )) as unknown as React.ReactNode
              }}
            />
          </div>

          <div className="text-sm text-purple-medium text-center mt-4">
            <FormattedMessage
              id={translations.pages.signUp.signInRedirect}
              values={{
                span: ((text: string) => (
                  <Link
                    to="/sign-in"
                    className="text-orange-light underline"
                    onClick={() => analytics.trackEvent('Auth - Switch to sign in', {})}
                  >
                    {text}
                  </Link>
                )) as unknown as React.ReactNode
              }}
            />
          </div>
        </div>
      )}
    </Form>
  );
};
