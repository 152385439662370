import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { Session } from '@/containers/SessionContainer';
import { Button } from '@chew/common/components/Button';

const text = translations.modals.digitalTaskModal.completed;

interface Props {
  points: number;
  onClose(): void;
}

export const DigitalTaskCompletedContent: React.FC<Props> = ({ points, onClose }) => {
  const { me } = Session.useContainer();

  return (
    <div>
      <p className="pb-2 text-sm text-center text-orange-light tracking-wide uppercase font-semibold">
        <FormattedMessage id={text.heading} />
      </p>

      <h1 className="text-3xl font-extrabold text-center">
        <FormattedMessage id={text.title} values={{ name: me?.fullName, br: <br /> }} />
      </h1>

      <p className="py-6 text-5xl font-extrabold text-center">
        <FormattedMessage id={text.score} values={{ points }} />
      </p>

      <Button type="submit" appearance="gradient-orange" className="w-full h-16" onClick={onClose}>
        <FormattedMessage id={text.button} />
      </Button>
    </div>
  );
};
