import { SubscriptionPlan } from '@/domains';

export enum ActionType {
  SubscriptionPlansLoaded,
  SubscriptionPlanSelectionChanged,
  OrderCreated,
  DiscountApplied
}

export interface SubscriptionPlansLoadedAction {
  type: ActionType.SubscriptionPlansLoaded;
  plans: SubscriptionPlan[];
}

export interface SubscriptionPlanSelectionChangedAction {
  type: ActionType.SubscriptionPlanSelectionChanged;
  plan: SubscriptionPlan;
}

export interface OrderCreatedAction {
  type: ActionType.OrderCreated;
  htmlSnippet: string;
  orderId: string;
}

export interface DiscountAppliedAction {
  type: ActionType.DiscountApplied;
}

export type Action =
  | SubscriptionPlansLoadedAction
  | SubscriptionPlanSelectionChangedAction
  | OrderCreatedAction
  | DiscountAppliedAction;

export interface State {
  subscriptionPlans?: SubscriptionPlan[];
  selectedPlan?: SubscriptionPlan;
  amount?: number;
  htmlSnippet?: string;
  orderId?: string;
  promocode?: string;
}

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.SubscriptionPlansLoaded:
      return { subscriptionPlans: action.plans, selectedPlan: action.plans[0] };
    case ActionType.SubscriptionPlanSelectionChanged:
      return { subscriptionPlans: state.subscriptionPlans, selectedPlan: action.plan };
    case ActionType.OrderCreated: {
      const { type, ...data } = action;
      return { ...state, ...data };
    }
    case ActionType.DiscountApplied: {
      const { type, ...data } = action;
      return { ...state, ...data };
    }
  }
};
