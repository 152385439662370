import { DigitalTask, DigitalTaskResult, DigitalTaskType } from '@/domains/digital-task';
import { authorizedApi } from '@chew/common/services/network/authorized-api';

interface GetDigitalTaskOptions {
  levelId: number;
}

export const getDigitalTask = ({ levelId }: GetDigitalTaskOptions) =>
  authorizedApi.get<DigitalTask<DigitalTaskType>>(`/v1/levels/${levelId}/digital-task`);

interface SubmitDigitalTaskAnswersOptions {
  levelId: number;
  answers: string[];
}

export const submitDigitalTaskAnswers = ({ levelId, answers }: SubmitDigitalTaskAnswersOptions) =>
  authorizedApi.post<DigitalTaskResult>(`/v1/levels/${levelId}/digital-task/answers`, { answers });
