import React from 'react';
import classnames from 'classnames';

import { Label } from '@chew/common/components/Label';
import { Input, Props as InputProps } from '@chew/common/components/Input';
import { withValidation } from '@chew/common/components/hoc/withValidation';

export interface Props extends InputProps {
  invalid?: boolean;
  label?: string;
  onIconClick?: () => any;
  inputClassName?: string;
  required?: boolean;
}

export const TextInput = (props: React.PropsWithChildren<Props>) => {
  const { invalid, className, inputClassName, label, onIconClick, required = false, id, ...rest } = props;

  return (
    <div className={classnames('relative w-full inline-flex flex-col', { 'text-gray-dark': rest.disabled }, className)}>
      {label && (
        <div className="text-xs">
          <Label htmlFor={id}>{label}</Label>

          {required && <span className="text-red ml-1">*</span>}
        </div>
      )}

      <Input
        className={classnames(
          'relative box-border bg-white h-[50px] px-4 py-2 border border-solid rounded-lg transition focus:border-orange-light focus:outline-none',
          {
            'border-purple-light': !invalid,
            'border-red-700': invalid
          },
          inputClassName
        )}
        {...{ id, ...rest }}
      />
    </div>
  );
};

export const TextInputWithValidation = withValidation(TextInput);
