import React from 'react';
import classnames from 'classnames';

interface Props {
  className?: string;
}

export const PagePadding: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <div {...props} className={classnames('px-4 sm:px-12 md:px-20', props.className)} />
);
