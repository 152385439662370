import React from 'react';
import Helmet from 'react-helmet';

import { AppContent } from '@/components/App/AppContent';
import { Session } from '@/containers/SessionContainer';
import { SWRDevTools } from 'swr-devtools';

import './styles/base.sass';
import 'tailwindcss/tailwind.css';
import './assets/fonts/cabinet-grotesk.css';

export const App: React.FC = () => {
  if (process.env.NODE_ENV === 'development')
    return (
      <Session.Provider>
        <Helmet defaultTitle="Chew" titleTemplate="%s - Chew" />

        <SWRDevTools>
          <AppContent />
        </SWRDevTools>
      </Session.Provider>
    );

  return (
    <Session.Provider>
      <Helmet defaultTitle="Chew" titleTemplate="%s - Chew" />
      <AppContent />
    </Session.Provider>
  );
};
