import React from 'react';
import classnames from 'classnames';

import { CardRow } from './CardRow';

export interface Props {
  className?: string;
}

interface StaticComponentsProps {
  Row: typeof CardRow;
}

export const Card: React.FC<React.PropsWithChildren<Props>> & StaticComponentsProps = ({ className, ...rest }) => {
  return (
    <div className={classnames('bg-white border border-solid border-gray-200 rounded-3xl', className)} {...rest} />
  );
};

Card.Row = CardRow;
