import React from 'react';

import { parsePosibleJSONString } from '@/util/me';
import { Session } from '@/containers/SessionContainer';
import { LegacyBadge } from '@chew/common/domains';

export const useBadges = () => {
  const { me } = Session.useContainer();

  return React.useMemo(() => parsePosibleJSONString<LegacyBadge[]>(me?.earnedBadges, []), [me]);
};
