import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { translations } from '@/locales';

import { Button } from '@chew/common/components/Button';

import passwordConfirmation from './password-confirmation.svg';

export const ResetPasswordConfirmation: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state) navigate('/sign-in');

  return (
    <React.Fragment>
      <img src={passwordConfirmation} className="h-24 animate-bounce" />

      <div className="text-2xl font-extrabold text-center mb-4">
        <FormattedMessage id={translations.pages.resetPasswordConfirmation.title} />
      </div>

      <div className="text-purple-medium text-center">
        <FormattedMessage
          id={translations.pages.resetPasswordConfirmation.subtitle}
          values={{
            br: <br />,
            orange: ((text: string) => <span className="text-orange-light">{text}</span>) as unknown as React.ReactNode,
            email: `${location.state}`
          }}
        />
      </div>

      <Button appearance="gradient-orange" className="mt-12 h-16" onClick={() => navigate('/sign-in')}>
        <FormattedMessage id={translations.inputs.buttons.signInRedirect} />
      </Button>
    </React.Fragment>
  );
};
