import React from 'react';
import { createContainer } from 'unstated-next';

import { analytics } from '@/services/analytics';

import {
  getProfile,
  login,
  LoginOptions,
  LoginResponse,
  loginWithSocial,
  logout as apiLogout,
  registerWithSocial,
  SocialAuthIntent,
  SocialAuthOptions
} from '@/services/api';
import { storage } from '@/services';
import { authorizedApi } from '@chew/common/services/network/authorized-api';
import { ResponseDataWrapper } from '@/util/fixtures/api';

enum ActionType {
  UpdateSession = 'update-session',
  ClearSession = 'clear-session',
  Error = 'error'
}

interface LoggedInAction {
  type: ActionType.UpdateSession;
  me: LoginResponse['user'];
  session: Session;
}

interface LoggedOutAction {
  type: ActionType.ClearSession;
}

interface ErrorAction {
  type: ActionType.Error;
  error: Error;
}

export type Action = LoggedInAction | LoggedOutAction | ErrorAction;

interface Session {
  accessToken: string;
}

export interface State {
  loading: boolean;
  session?: Session;
  error?: Error;
  me?: LoginResponse['user'];
}

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.UpdateSession: {
      const { me, session } = action;

      return { loading: false, me, session };
    }
    case ActionType.ClearSession:
      return { loading: false };
    case ActionType.Error:
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

export const useSession = () => {
  const [state, dispatch] = React.useReducer(reducer, { loading: true, session: storage.getItem<Session>('session') });

  const onSessionUpdated = (session?: Session) => () => {
    storage.setItem('session', session);

    authorizedApi.token = session?.accessToken ?? null;
  };

  const clearSession = () => dispatch({ type: ActionType.ClearSession });

  React.useEffect(() => {
    onSessionUpdated(state.session)();

    authorizedApi.configure({
      onUnauthorized: () => Promise.resolve().then(clearSession).then(onSessionUpdated()),
      onAccessTokenExpired: () => Promise.resolve().then(clearSession).then(onSessionUpdated())
    });
  }, [state.session]);

  const loadMe = React.useCallback(async () => {
    const session = state.session;

    if (!session) return;

    return getProfile()
      .then((me) => {
        dispatch({ type: ActionType.UpdateSession, me, session });

        analytics.trackUser((user) => {
          return user
            .set('email', me.email)
            .set('name', me.fullName)
            .set('ageGroup', me.age)
            .set('level', (me.levelsCompleted + 1).toString())
            .set('hasProfilePic', me.avatar ? 'yes' : 'no')
            .set('subscriber', me.premiumSubscription === 1 ? 'yes' : 'no');
        });
        return me;
      })
      .catch(() => clearSession());
  }, [state.session]);

  React.useEffect(() => {
    loadMe();
  }, [loadMe]);

  const loginWithEmailAndPassword = React.useCallback(
    (options: LoginOptions) =>
      login(options).then(({ user: me, token: accessToken }) => {
        analytics.setCurrentUser(me.email);
        analytics.trackEvent('Auth - Sign in', { type: 'email' });
        dispatch({ type: ActionType.UpdateSession, me, session: { accessToken } });

        onSessionUpdated({ accessToken })();
      }),
    []
  );

  const handleSocialFailure = (
    authIntent: SocialAuthIntent,
    response: ResponseDataWrapper<LoginResponse>,
    options: SocialAuthOptions
  ) => {
    switch (response.data.message) {
      case 'User is already exists.': {
        return authIntent === SocialAuthIntent.Registration ? loginWithSocial(options) : registerWithSocial(options);
      }
      case 'User not found.': {
        return registerWithSocial(options);
      }
    }

    return authIntent === SocialAuthIntent.Registration ? loginWithSocial(options) : registerWithSocial(options);
  };

  const handleSocialAuthSuccess = (
    authIntent: SocialAuthIntent,
    options: SocialAuthOptions,
    response: ResponseDataWrapper<LoginResponse>
  ) => {
    analytics.setCurrentUser(response.data.user.email);

    analytics.trackEvent(authIntent === SocialAuthIntent.Registration ? 'Auth - Sign up' : 'Auth - Sign in', {
      type: options.oauth_client
    });
    dispatch({
      type: ActionType.UpdateSession,
      me: response.data.user,
      session: { accessToken: response.data.token }
    });

    onSessionUpdated({ accessToken: response.data.token })();
  };

  const socialAuth = React.useCallback(async (authIntent: SocialAuthIntent, options: SocialAuthOptions) => {
    const action = authIntent === SocialAuthIntent.Registration ? registerWithSocial : loginWithSocial;

    // LEGACY: Old API returns status code 200 with success false
    const response = await action(options).catch((error) => {
      dispatch({ type: ActionType.Error, error });
    });

    if (!response) return;

    if (!response.data.success) {
      return handleSocialFailure(authIntent, response, options).then((newResponse) =>
        handleSocialAuthSuccess(authIntent, options, newResponse)
      );
    }

    return handleSocialAuthSuccess(authIntent, options, response);
  }, []);

  React.useEffect(() => {
    analytics.setCurrentUser(state.me?.email);
  }, [state.me]);

  const logout = React.useCallback(async () => {
    apiLogout()
      .then(clearSession)
      .then(onSessionUpdated())
      .then(() => analytics.trackEvent('navigation', { type: 'Log out' }));
  }, []);

  return React.useMemo(
    () => ({
      loading: state.loading,
      error: state.error,
      me: state.me,
      authenticated: !!state.session,
      loadMe,
      logout,
      loginWithEmailAndPassword,
      socialAuth
    }),
    [state.loading, state.error, state.me, state.session, logout, loadMe, loginWithEmailAndPassword, socialAuth]
  );
};

export const Session = createContainer(useSession);
